import { connect } from "react-redux";
import React, { useEffect } from "react";
import { CableList } from "../../models/cableModel";
import { FMSLocationList } from "../../models/fmsLocationModel";
import { FMSList } from "../../models/fmsModel";
import { StoreState } from "../../models/reduxModels";
import { StationList } from "../../models/stationModel";
import { ZoneList } from "../../models/zoneModels";
import { showFmsWiseCableAction } from "../../Stores/actions/cableAction";
import { GetFMSLocationWiseAction } from "../../Stores/actions/fmsAction";
import { getStationLocation } from "../../Stores/actions/fmsLocationAction";
import {
  GetDamageFibreLoad,
  GetUtilzCable,
} from "../../Stores/actions/ReportAction";
import { getZoneStations } from "../../Stores/actions/stationAction";
import CableUtilizationView from "./CableUtilizationView";
import { UtilizeCable } from "../../models/ReportModel";

function CableUtilizationReport({
  GetUtilzCable,
  getZoneStations,
  getStationLocation,
  GetFMSLocationWiseAction,
  showFmsWiseCableAction,
  allzone,
  allstation,
  allfmslocation,
  allFMS,
  cable_list,
  utilizeCable,
  GetDamageFibreLoad,
}: UtilzProps) {
  useEffect(() => {
    GetUtilzCable();
    GetDamageFibreLoad();
  }, []);
  const Filters = (props: any) => {
    console.log(props);
    GetUtilzCable(props);
  };
  const clearFilter = () => {
    GetUtilzCable();
    GetDamageFibreLoad();
  };
  return (
    <CableUtilizationView
      getZoneStations={getZoneStations}
      getStationLocation={getStationLocation}
      GetFMSLocationWiseAction={(id: number) =>
        GetFMSLocationWiseAction({ location_id: +id })
      }
      showFmsWiseCableAction={showFmsWiseCableAction}
      allzone={allzone}
      cable_list={cable_list}
      allstation={allstation}
      allfmslocation={allfmslocation}
      allFMS={allFMS}
      clear={clearFilter}
      Filters={Filters}
      utilizeCable={utilizeCable}
    />
  );
}

const mapStateToProps = (state: StoreState) => {
  return {
    utilizeCable: state.report.utilz_cable,
    allzone: state.zone.zone_list,
    allstation: state.station.station_list,
    cable_list: state.cable.cable_list,
    allFMS: state.fms.fms_list,
    allfmslocation: state.fmslocation.fmslocation_list,
  };
};
const mapDispatchToProps = {
  getZoneStations,
  getStationLocation,
  GetFMSLocationWiseAction,
  showFmsWiseCableAction,
  GetUtilzCable,
  GetDamageFibreLoad,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CableUtilizationReport);
interface UtilzProps {
  GetUtilzCable?: any;
  getZoneStations?: any;
  GetFMSLocationWiseAction?: any;
  getStationLocation?: any;
  showFmsWiseCableAction?: any;
  allzone: ZoneList[];
  allstation: StationList[];
  allfmslocation: FMSLocationList[];
  allFMS: FMSList[];
  cable_list: any[];
  utilizeCable: UtilizeCable[];
  GetDamageFibreLoad?: any;
}
