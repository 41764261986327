// Dev Url
// export const baseServiceUrl = "http://65.0.212.108:8021/";
// Prod Url
export const baseServiceUrl = "https://owbyngz1vi.execute-api.ap-south-1.amazonaws.com/v1/"

export const environment = {
  url: {
    LoginUrl: "api/v2/login",
    GetAllZoneUrl: "api/v2/zones/getall ",
    ZoneAdddUrl: "api/v2/zones/create",
    GetAllStationUrl: "api/v2/stations/getall",
    StationAdddUrl: "api/v2/stations/create",
    DeleteZoneUrl: "api/v2/zones/delete",
    ZoneGetByIdUrl: "api/v2/zones",
    ZoneUpdateUrl: "api/v2/zones/update",
    GetAllFMSLocationUrl: "api/v2/location/getall",
    FMSLocationAdddUrl: "api/v2/location/create",
    DeleteFMSLocationUrl: "api/v2/location/delete",
    FMSLocationGetByIdUrl: "api/v2/location",
    FMSLocationUpdateUrl: "api/v2/location/update",
    StationDeleteUrl: "api/v2/stations/delete",
    StationGetByIdUrl: "api/v2/stations",
    StationUpdateUrl: "api/v2/stations/update",
    GetStationByZoneIdUrl: "api/v2/stations/getbyzone",
    GetFibreByFmsCableUrl: "api/v2/cabledetails/fibreByfmscables",

    GetLocationByStationUrl: "api/v2/location/getbystation",
    FibreMasterListUrl: "api/v2/fibre/getall",
    FibreAddUrl: "api/v2/fibre/create",
    GetColorCodeUrl: "api/v2/colourmaster/getall",
    FibreUpdateUrl: "api/v2/fibre/update",
    DeleteFibreUrl: "api/v2/fibre/delete",
    GetAllTubeUrl: "api/v2/tubes/getall",
    GetAllMasterCableUrl: "api/v2/cable/getall",
    GetCableDetailUrl: "api/v2/cable",
    TubeUpdateUrl: "api/v2/tubes/update",
    DeleteTubeUrl: "api/v2/tubes/delete",
    GetTubeDetailUrl: "api/v2/tubes",
    CableCreateUrl: "api/v2/cable/create",
    TubeAddUrl: "api/v2/tubes/create",

    FMSAddUrl: "api/v2/fms/create",
    GetFMSByLocation: "api/v2/fms/bylocation",
    GetAllFMSUrl: "api/v2/fms/getall",
    DeleteFMSUrl: "api/v2/fms/delete",
    GetFMSById: "api/v2/fms",
    editFMS: "api/v2/fms/update",
    editCableUrl: "api/v2/cable/update",
    cableDeleteUrl: "api/v2/cable/delete",
    CableGetAllUrl: "api/v2/cabledetails/getall",
    CableDetailCreateUrl: "api/v2/cabledetails/create",
    CableDetailSingleUrl: "api/v2/cabledetails",
    UpdateCableUrl: "api/v2/cabledetails/update",
    DeleteCableUrl: "api/v2/cabledetails/delete",
    GetAllServiceUrl: "api/v2/service/getall",
    ServiceAdddUrl: "api/v2/service/create",
    ServiceGetByIdUrl: "api/v2/service",
    ServiceUpdateUrl: "api/v2/service/update",
    DeleteServiceUrl: "api/v2/service/delete",
    Show_FMS_Wise_Cable: "api/v2/cabledetails/byfms",
    CableDetailUrl: "api/v2/cabledetails/service",
    Show_cable_Tree: "api/v2/cable",
    CableServiceUpdate: "api/v2/cabledetails/service/update",
    FmsPortDetailUrl: "api/v2/fms/getfmsportmapping",
    FmsPortMappingUrl: "api/v2/fms/mapfibreport",
    DomainTypeUrl: "api/v2/shared/domain/bydomaintype",
    cableAddInitialUrl: "api/v2/cabledetails/create",
    fibrebycablefmsUrl: "api/v2/cabledetails/fibrebycablefms",
    CableDuplicateCheckUrl: "api/v2/cabledetails/checkduplicate",
    getAllFMSbyStationIDURL: "api/v2/fms/bystation",
    getAvailablePortUrl: "api/v2/fms/availableport",
    getCableByFibreFMS: "api/v2/cabledetails/cablefibrebyfms",
    getFibreByService: "api/v2/cabledetails/fibrebyservice",
    getTraceFibre: "api/v2/cabledetails/tracefibre",
    GetFdbByFmsUrl: "api/v2/fms/getallfdbfodbfms",
    GetServiceOccupencyUrl: "api/v2/cabledetails/serviceoccupancy",
    GetReportUrl: "api/v2/cabledetails/getalltransaction",
    FDBFMSFabUrl: "api/v2/cabledetails/getallfdbfibre",
    getAllAssetValueURL: "api/v2/cabledetails/getallcablefmscost",
    getAllFDBByFMS: "api/v2/fms/getallfdbbyfms",
    GetJointInfoUrl: "api/v2/fms/getjoininfo",
    DamageFibreGet: "api/v2/cabledetails/getdamagefibre",
    changePasswordUrl: "api/v2/users/changepassword",
    getcableTypeTemplateUrl: "api/v2/colourmaster/gettemplatebyid",
    GetSchematicFmsUrl: "api/v2/fms/schematicview",
    UtilzCableGet: "api/v2/cabledetails/availabilityutilizationbycable",
    CableByServiceGet: "api/v2/cabledetails/servicewisecableinfo",
    CableWiseReportGet: "api/v2/cabledetails/cablewiseserviceinfo",
    EndToEndFibreTraceUrl: "api/v2/cabledetails/endtoendfibretrace",
    ServiceWiseCableDetailUrl: "api/v2/cabledetails/servicewisecabledetailinfo",
    BulkReportListUrl: "api/v2/shared/upload/report",
    BulkFileUploadUrl: "api/v2/shared/file/upload",
    DownloadReportUrl: "api/v2/shared/download/report",
    Neo4jDetailsFromDomain: "api/v2/shared/domain/bydomaintype",
    EndToEndLossFromCableUtilization: "api/v2/cabledetails/endtoendlos",
    OFCUnterminatedFiberDetails: "api/v2/cabledetails/unterminatedfibre"
  },
};
