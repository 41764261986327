import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import React, { useState } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Controller, useForm } from "react-hook-form";
import Autocompletes from "../../components/Autocompletes";
import {
  Box,
  Accordion,
  AccordionSummary,
  Button,
  AccordionDetails,
  Grid,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  tableCellClasses,
  TableCell,
  styled,
} from "@mui/material";
import { ZoneList } from "../../models/zoneModels";
import { StationList } from "../../models/stationModel";
import { FMSLocationList } from "../../models/fmsLocationModel";
import { CableList, FMSList } from "../../models/fmsModel";
import { CableReport } from "../../models/ReportModel";

function CableWiseReportView({
  allzone,
  getZoneStations,
  allstation,
  getStationLocation,
  allfmslocation,
  GetFMSLocationWiseAction,
  allFMS,
  showFmsWiseCableAction,
  cable_list,
  clear,
  Filters,
  cableReport,
}: CableWiseReportProps) {
  const [expanded, setExpanded] = useState<string | false>("panel1");
  const handleChange1 =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
  } = useForm();
  const stationFilter = (id: number) => {
    getZoneStations(id);
  };
  const { errors } = formState;
  const Filter = (props: any) => {
    Filters(props);
    setExpanded(false);
  };
  const Clear = () => {
    reset();
    clear();
  };
  return (
    <Box>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange1("panel1")}
      >
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <Box
            sx={{
              display: "flex",
              flexDirection: "row-reverse",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              endIcon={<FilterListIcon />}
              sx={{ color: "#fff" }}
              onClick={() => handleChange1("panel1")}
            >
              Filter
            </Button>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <form>
            <Grid container justifyContent="space-around" alignItems="center">
              <Grid item xs={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={4}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography style={{ fontWeight: 550 }}>Zone</Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography>:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name={"zone_id"}
                      defaultValue={""}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocompletes
                          option={allzone}
                          keys={"zone_id"}
                          label={"zone_name"}
                          onBlur={onBlur}
                          onChange={(value: any) => (
                            onChange(value), !!value && stationFilter(+value)
                          )}
                          placeholder={"Zone"}
                          value={value}
                          errors={errors["zone_id"]}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={4}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ fontWeight: 550 }}>
                        Station
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        control={control}
                        name={"station_id"}
                        defaultValue={""}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Autocompletes
                            hasInfo={false}
                            infoEvent={() => {}}
                            option={allstation}
                            keys={"station_id"}
                            label={"station_code"}
                            onBlur={onBlur}
                            onChange={(value: any) => (
                              onChange(value),
                              !!value && getStationLocation(+value)
                            )}
                            placeholder={"Station"}
                            value={value}
                            errors={errors["station_id"]}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-around" alignItems="center">
              <Grid item xs={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={4}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography style={{ fontWeight: 550 }}>
                      Select Location
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    :
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name={"location_id"}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocompletes
                          hasInfo={false}
                          option={allfmslocation}
                          keys={"location_id"}
                          label={"location_name"}
                          onBlur={onBlur}
                          onChange={(value: any) => (
                            onChange(value),
                            !!value && GetFMSLocationWiseAction(+value)
                          )}
                          placeholder={"Select Location"}
                          value={value}
                          errors={errors["location_id"]}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={4}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography style={{ fontWeight: 550 }}>
                      Select FMS
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    :
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name={"fms_id"}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocompletes
                          hasInfo={false}
                          option={allFMS}
                          keys={"fms_id"}
                          label={"fms_code"}
                          onBlur={onBlur}
                          onChange={(value: any) => (
                            onChange(value),
                            !!value && showFmsWiseCableAction(+value)
                          )}
                          placeholder={"Select FMS"}
                          value={value}
                          errors={errors["fms_id"]}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-around" alignItems="center">
              <Grid item xs={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={4}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography style={{ fontWeight: 550 }}>
                      Select Cable <span style={{ color: `red` }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    :
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name={"cable_detail_id"}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocompletes
                          hasInfo={false}
                          option={cable_list}
                          keys={"cable_detail_id"}
                          label={"cable_short_code"}
                          onBlur={onBlur}
                          onChange={(value: any) => onChange(value)}
                          placeholder={"Select Cable"}
                          value={value}
                          errors={errors["cable_detail_id"]}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5}></Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end ",
                marginTop: "1.5rem",
                marginRight: "3rem",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  color: "#fff",
                  backgroundColor: "#808090",
                }}
                onClick={() => Clear()}
              >
                Clear
              </Button>
              <Button
                variant="contained"
                sx={{
                  color: "#fff",
                  backgroundColor: "#2e7d32",
                  margin: "0 1rem",
                }}
                onClick={handleSubmit(Filter)}
              >
                Show
              </Button>

            
            </Box>
          </form>
        </AccordionDetails>
      </Accordion>

      <Paper
        elevation={3}
        sx={{
          width: "100%",
          margin: "1rem 0",
          paddingTop: "1rem",
        }}
      >
        <Box sx={{ width: "100%", height: "100%" }}>
          <Box>
            <Typography
              sx={{
                color: "gray",
                textAlign: "center",
                textShadow: "0px 0px 0px #fff, 3px 3px 0px rgba(0,0,0,0.15)",
                fontWeight: 620,
              }}
              variant="h5"
            >
              {"Cable Wise Report"}
            </Typography>
          </Box>
          <TableContainer sx={{ marginTop: "1.4rem" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {/* <th
                  style={{
                    border: "1px solid gray",
                    borderCollapse: "collapse",
                    fontSize: "15px",
                    fontWeight: "500",
                  }}
                >
                  Short Code
                </th> */}
                  <StyledTableCell
                    style={{
                      borderCollapse: "collapse",
                    }}
                  >
                    Service Name
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      borderCollapse: "collapse",
                    }}
                  >
                    Tube Fibre Color (Source A)
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      borderCollapse: "collapse",
                    }}
                  >
                    FMS Code (Source A)
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      borderCollapse: "collapse",
                    }}
                  >
                    Station Name (Source A)
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      borderCollapse: "collapse",
                    }}
                  >
                    Tube Fibre Color (Source B)
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      borderCollapse: "collapse",
                    }}
                  >
                    FMS Code (Source B)
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      borderCollapse: "collapse",
                    }}
                  >
                    Station Name (Source B)
                  </StyledTableCell>
                  {/* <StyledTableCell
                    style={{
                      borderCollapse: "collapse",
                    }}
                  >
                    Adapter Type
                  </StyledTableCell> */}

                  <StyledTableCell
                    style={{
                      borderCollapse: "collapse",
                    }}
                  >
                    Fibre Status
                  </StyledTableCell>

                  <StyledTableCell
                    style={{
                      borderCollapse: "collapse",
                    }}
                  >
                    Fibre km
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      borderCollapse: "collapse",
                    }}
                  >
                   End to End Loss
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!!cableReport && cableReport.length > 0 ? (
                  cableReport?.map((tData: CableReport, index: number) => (
                    <StyledTableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                    >
                      {/* <td
                      style={{
                        border: "1px solid gray",
                        borderCollapse: "collapse",
                        fontSize: "13px",
                        fontWeight: "500",
                      }}
                    >
                      {tData.cable_short_code ? tData.cable_short_code : "NA"}
                    </td> */}
                      <StyledTableCell
                        style={{
                          borderCollapse: "collapse",
                        }}
                      >
                        {tData.service_name}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          borderCollapse: "collapse",
                        }}
                      >
                        {tData.tube_colour} - {tData.fibre_colour}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          borderCollapse: "collapse",
                        }}
                      >
                        {tData.source_fms_code}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          borderCollapse: "collapse",
                        }}
                      >
                        {tData.source_station_name}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          borderCollapse: "collapse",
                        }}
                      >
                        {tData.des_tube_colour} - {tData.des_fibre_colour}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          borderCollapse: "collapse",
                        }}
                      >
                        {tData.destination_fms_code}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          borderCollapse: "collapse",
                        }}
                      >
                        {tData.destination_station_name}
                      </StyledTableCell>
                      {/* <StyledTableCell
                        style={{
                          borderCollapse: "collapse",
                        }}
                      >
                        {tData.adapter_type}
                      </StyledTableCell> */}

                      <StyledTableCell
                        style={{
                          borderCollapse: "collapse",
                        }}
                      >
                        {tData.fibre_status}
                      </StyledTableCell>

                      <StyledTableCell
                        style={{
                          borderCollapse: "collapse",
                        }}
                      >
                        {tData.fibre_length} mt
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          borderCollapse: "collapse",
                        }}
                      >
                        {tData?.loss ? `${tData?.loss?.toFixed(2)} dB` : 'N/A'}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <tr
                    style={{
                      border: "1px solid gray",
                      textAlign: "center",
                    }}
                  >
                    <td
                      colSpan={10}
                      style={{
                        // border: "1px solid gray",
                        borderCollapse: "collapse",
                      }}
                    >
                      No record found
                    </td>
                  </tr>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {!!cableReport && !!cableReport.length && (
            <Box style={{display:'flex', justifyContent:'center', alignItems:'center', paddingBottom: 20, paddingTop: 20}}>
                <Button
                  variant="contained"
                  sx={{ color: "#fff"  }}
                  onClick={() => {
                    var temp: any[] = [];
                    for (let i = 0; i < cableReport.length; i++) {
                      temp[i] = [
                        cableReport[i].service_name,
                        cableReport[i].source_fms_code,
                        cableReport[i].tube_colour +
                          " - " +
                          cableReport[i].fibre_colour,
                        cableReport[i].source_station_name,
                        cableReport[i].des_tube_colour +
                          " - " +
                          cableReport[i].des_fibre_colour,

                        cableReport[i].destination_fms_code,

                        cableReport[i].destination_station_name,
                        // cableReport[i].adapter_type,
                        cableReport[i].fibre_status,

                        `${cableReport[i].fibre_length} mt`,
                        `${cableReport[i].loss} dB`,
                      ];
                    }
                    pdfMake.vfs = pdfFonts.pdfMake.vfs;
                    var dd: any = {
                      pageOrientation: "landscape",
                      content: [
                        {
                          text: "Cable Wise Report",
                          style: "header",
                        },
                        {
                          style: "tableExample",
                          table: {
                            headerRows: 1,
                            body: [
                              [
                                {
                                  text: "Service Name",
                                  style: "tableHeader",
                                },
                                {
                                  text: "FMS Code (Source A)",
                                  style: "tableHeader",
                                },
                                {
                                  text: "Tube Fibre Color (Source A)",
                                  style: "tableHeader",
                                },
                                {
                                  text: "Station Name (Source A) ",
                                  style: "tableHeader",
                                },
                                {
                                  text: "Tube Fibre Color (Source B)",
                                  style: "tableHeader",
                                },
                                {
                                  text: "FMS Code (Source B)",
                                  style: "tableHeader",
                                },
                                {
                                  text: "Station Name (Source B)",
                                  style: "tableHeader",
                                },
                                // {
                                //   text: "Adapter Type",
                                //   style: "tableHeader",
                                // },
                                {
                                  text: "Fibre Status",
                                  style: "tableHeader",
                                },

                                {
                                  text: "Fibre km",
                                  style: "tableHeader",
                                },
                                {
                                  text: "End to End Loss(dB)",
                                  style: "tableHeader",
                                },
                              ],

                              ...temp,
                            ],
                          },
                          layout: {
                            fillColor: function (rowIndex: any) {
                              return rowIndex % 2 === 0 ? "#CCCCCC" : null;
                            },
                            hLineWidth: function (i: any, node: any) {
                              return i === 0 || i === node.table.body.length
                                ? 2
                                : 1;
                            },
                            vLineWidth: function (i: any, node: any) {
                              return i === 0 || i === node.table.widths.length
                                ? 2
                                : 1;
                            },
                            hLineColor: function (i: any, node: any) {
                              return i === 0 || i === node.table.body.length
                                ? "black"
                                : "gray";
                            },
                            vLineColor: function (i: any, node: any) {
                              return i === 0 || i === node.table.widths.length
                                ? "black"
                                : "gray";
                            },
                            // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                            // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                            // paddingLeft: function(i, node) { return 4; },
                            // paddingRight: function(i, node) { return 4; },
                            // paddingTop: function(i, node) { return 2; },
                            // paddingBottom: function(i, node) { return 2; },
                            // fillColor: function (rowIndex, node, columnIndex) { return null; }
                          },
                        },
                      ],
                      styles: {
                        header: {
                          fontSize: 18,
                          bold: true,
                          margin: [0, 0, 0, 10],
                          color: "#9d56a2",
                        },
                        subheader: {
                          fontSize: 16,
                          bold: true,
                          margin: [0, 10, 0, 5],
                        },
                        tableExample: {
                          margin: [0, 5, 0, 15],
                          fontSize: 12,
                        },
                        tableHeader: {
                          bold: true,
                          fontSize: 12,
                          color: "white",
                          fillColor: "#035779",
                        },
                      },
                      defaultStyle: {
                        // alignment: 'justify'
                      },
                    };

                    pdfMake.createPdf(dd).open();
                  }}
                >
                  Generate PDF
                </Button>
                </Box>
              )}
        </Box>
      </Paper>
    </Box>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#035779",
    color: theme.palette.common.white,
    padding: 10,
    paddingLeft: 15,
    fontWeight: 500,
    fontSize: 14,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 8,
    paddingLeft: 15,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export default CableWiseReportView;
interface CableWiseReportProps {
  allzone: ZoneList[];
  allstation: StationList[];
  cable_list: CableList[];
  allFMS: FMSList[];
  allfmslocation: FMSLocationList[];
  getZoneStations?: any;
  getStationLocation?: any;
  GetFMSLocationWiseAction?: any;
  showFmsWiseCableAction?: any;
  Filters?: any;
  clear?: any;
  cableReport: CableReport[];
}
