import { useState, useEffect } from "react";
import { connect } from "react-redux";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import "./CableTreeDialog.css";
import { CableTree } from "../models/CableTreeModel";
import { GetCableTreeAction } from "../Stores/actions/cableTreeAction";
import { StoreState } from "../models/reduxModels";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  Stack,
  Slide,
  Grid,
  Paper,
  Tooltip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";
import { TreeView } from "@mui/lab";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import {
  CableDetailLoadAction,
  removeCableDetailLoadAction,
} from "../Stores/actions/cableAction";
import { CableDetails } from "../models/cableModel";
import { DestinationCableInfo, SourceCableInfo } from "../models/cableModel";

const CableTreeDialog = ({
  isOpen,
  setOpen,
  cable_list,
  GetCableTreeAction,
  cable_id,
  show_pdf,
  CableDetailLoadAction,
  cable_details,
  removeCableDetailLoadAction,
}: CableTreeProps) => {
  const handleClose = () => {
    setOpen(false);
  };
  const generatePdf = () => {
    var temp: any[] = [];
    var temp1: any[] = [];
    if (!!cable_details)
      for (let i = 0; i < cable_details.sourceCableInfo.length; i++) {
        temp[i] = [
          ` ${cable_details.sourceCableInfo[i].tube_colour || "-"}
      ${cable_details.sourceCableInfo[i].fibre_colour || "-"}`,
          cable_details.sourceCableInfo[i].fibre_type,
          cable_details.sourceCableInfo[i].fibre_status,
          cable_details.sourceCableInfo[i].service_type || " N/A",
          cable_details.sourceCableInfo[i].service_name || " N/A",
          cable_details.sourceCableInfo[i].spliced_fibre_code || " N/A",
          cable_details.sourceCableInfo[i].spliced_fibre_code || " N/A",
          cable_details.sourceCableInfo[i].fdb_fms_code || " N/A",
          cable_details.sourceCableInfo[i].adapter_position || " N/A",
          cable_details.sourceCableInfo[i].adapter_type || " N/A",
          cable_details.sourceCableInfo[i].ferule_name || " N/A",
          cable_details.sourceCableInfo[i].remarks || " N/A",
        ];
      }
    if (!!cable_details)
      for (let j = 0; j < cable_details.destinationCableInfo.length; j++) {
        temp1[j] = [
          ` ${cable_details.destinationCableInfo[j].tube_colour || "-"}
        ${cable_details.destinationCableInfo[j].fibre_colour || "-"}`,
          cable_details.destinationCableInfo[j].fibre_type,
          cable_details.destinationCableInfo[j].fibre_status,
          cable_details.destinationCableInfo[j].service_type || " N/A",
          cable_details.destinationCableInfo[j].service_name || " N/A",
          cable_details.destinationCableInfo[j].spliced_fibre_code || " N/A",
          cable_details.destinationCableInfo[j].spliced_fibre_code || " N/A",
          cable_details.destinationCableInfo[j].fdb_fms_code || " N/A",
          cable_details.destinationCableInfo[j].adapter_position || " N/A",
          cable_details.destinationCableInfo[j].adapter_type || " N/A",
          cable_details.destinationCableInfo[j].ferule_name || " N/A",
          cable_details.destinationCableInfo[j].remarks || " N/A",
        ];
      }
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    var dd: any = {
      pageOrientation: "landscape",
      content: [
        {
          text:
            (cable_details?.cableInfo?.cable_short_code
              ? cable_details.cableInfo.cable_short_code
              : "N/A") +
            " " +
            "Cable Report",
          style: "header",
        },
        {
          text: `Source A`,
          style: "subheader",
        },
        {
          style: "tableExample",
          table: {
            headerRows: 1,
            body: [
              [
                {
                  text: "Tube Fibre Color ",
                  style: "tableHeader",
                },
                {
                  text: "Fibre type",
                  style: "tableHeader",
                },
                {
                  text: "Fibre Status",
                  style: "tableHeader",
                },
                {
                  text: "Service Status",
                  style: "tableHeader",
                },
                {
                  text: "Service Name",
                  style: "tableHeader",
                },
                {
                  text: "Spliced With ",
                  style: "tableHeader",
                },
                {
                  text: "Patch With",
                  style: "tableHeader",
                },
                {
                  text: "Terminated FDB",
                  style: "tableHeader",
                },
                {
                  text: "Adt. Position",
                  style: "tableHeader",
                },
                {
                  text: "Adt.Type",
                  style: "tableHeader",
                },
                {
                  text: "Ferrule Name",
                  style: "tableHeader",
                },
                {
                  text: "Remarks",
                  style: "tableHeader",
                },
              ],

              ...temp,
            ],
          },
          layout: {
            fillColor: function (rowIndex: any) {
              return rowIndex % 2 === 0 ? "#CCCCCC" : null;
            },
            hLineWidth: function (i: any, node: any) {
              return i === 0 || i === node.table.body.length ? 2 : 1;
            },
            vLineWidth: function (i: any, node: any) {
              return i === 0 || i === node.table.widths.length ? 2 : 1;
            },
            hLineColor: function (i: any, node: any) {
              return i === 0 || i === node.table.body.length ? "black" : "gray";
            },
            vLineColor: function (i: any, node: any) {
              return i === 0 || i === node.table.widths.length
                ? "black"
                : "gray";
            },
          },
        },
        {
          text: `Source B`,
          style: "subheader",
        },
        {
          style: "tableExample",
          table: {
            headerRows: 1,
            body: [
              [
                {
                  text: "Tube Fibre Color ",
                  style: "tableHeader",
                },
                {
                  text: "Fibre type",
                  style: "tableHeader",
                },
                {
                  text: "Fibre Status",
                  style: "tableHeader",
                },
                {
                  text: "Service Status",
                  style: "tableHeader",
                },
                {
                  text: "Service Name",
                  style: "tableHeader",
                },
                {
                  text: "Spliced With ",
                  style: "tableHeader",
                },
                {
                  text: "Patch With",
                  style: "tableHeader",
                },
                {
                  text: "Terminated FDB",
                  style: "tableHeader",
                },
                {
                  text: "Adt. Position",
                  style: "tableHeader",
                },
                {
                  text: "Adt.Type",
                  style: "tableHeader",
                },
                {
                  text: "Ferrule Name",
                  style: "tableHeader",
                },
                {
                  text: "Remarks",
                  style: "tableHeader",
                },
              ],

              ...temp1,
            ],
          },
          layout: {
            fillColor: function (rowIndex: any) {
              return rowIndex % 2 === 0 ? "#CCCCCC" : null;
            },
            hLineWidth: function (i: any, node: any) {
              return i === 0 || i === node.table.body.length ? 2 : 1;
            },
            vLineWidth: function (i: any, node: any) {
              return i === 0 || i === node.table.widths.length ? 2 : 1;
            },
            hLineColor: function (i: any, node: any) {
              return i === 0 || i === node.table.body.length ? "black" : "gray";
            },
            vLineColor: function (i: any, node: any) {
              return i === 0 || i === node.table.widths.length
                ? "black"
                : "gray";
            },
          },
        },
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10],
          // color: "#353535",
          color: "#9d56a2",
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 5],
        },
        tableExample: {
          margin: [0, 5, 0, 15],
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          // color: "black",
          color: "white",
          fillColor: "#035779",
        },
      },
      defaultStyle: {
        // alignment: 'justify'
      },
    };

    pdfMake.createPdf(dd).open();
  };

  const [expanded, setExpanded] = useState<string[]>([]);
  useEffect(() => {
    if (isOpen && cable_id !== 0) {
      CableDetailLoadAction(cable_id);
      return () => {
        removeCableDetailLoadAction();
      };
    }
  }, [isOpen]);

  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };
  // const Item = styled(Paper)(({ theme }) => ({
  //   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  //   ...theme.typography.body2,
  //   padding: theme.spacing(1),
  //   textAlign: 'center',
  //   color: theme.palette.text.secondary,
  // }));

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionComponent={Transition}
      fullScreen
      style={{ padding: 0 }}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          textAlign: "center",
          backgroundColor: "#065c7e",
          color: "#fff",
          fontSize: "1.4rem",
          padding: 0.5,
        }}
      >
        Cable Info
      </DialogTitle>
      <DialogContent style={{ padding: 0 }}>
        <DialogContentText
          id="alert-dialog-description"
          sx={{ overflow: "hidden", padding: 0 }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              position: "fixed",
              width: "99%",
              borderBottom: "1px solid #065c7e",
            }}
          >
            <Grid container style={{ marginTop: 0, marginLeft: 50 }}>
              <Grid item xs={3}>
                <h4
                  style={{
                    minWidth: "18%",
                    color: "065c7e",
                    fontWeight: "500",
                  }}
                >
                  Cable Actual Length :
                </h4>
              </Grid>
              <Grid item xs={3}>
                <h4
                  style={{
                    marginLeft: "10%",
                    minWidth: 250,
                    color: "#000",
                    fontWeight: "350",
                  }}
                >
                  {cable_details?.cableInfo?.cable_actual_length &&
                    cable_details?.cableInfo?.cable_actual_length + " mt"}
                </h4>
              </Grid>
              {/* <Grid item xs={2}>
                <h4 style={{ marginLeft: "15%", fontWeight: "500" }}>
                  Cable Color :
                </h4>
              </Grid> */}
              {/* <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
                <h4
                  style={{
                    color: "#000",
                    fontWeight: "350",
                    textAlign: "center",
                  }}
                >
                  {!!cable_details?.cableInfo?.cable_colour &&
                    cable_details.cableInfo.cable_colour}
                </h4>
                {!!cable_details?.cableInfo?.second_cable_colour && (
                  <>
                    <h4>-</h4>
                    <h4 style={{ color: "#000", fontWeight: "350" }}>
                      {!!cable_details?.cableInfo?.second_cable_colour &&
                        cable_details?.cableInfo?.second_cable_colour}
                    </h4>
                  </>
                )}
              </Grid> */}
              <Grid item xs={3}>
                <h4 style={{ minWidth: "18%", fontWeight: "500" }}>
                  Cable Short Code :
                </h4>
              </Grid>
              <Grid item xs={3}>
                <h4
                  style={{
                    marginLeft: "10%",
                    minWidth: 250,
                    color: "#000",
                    fontWeight: "350",
                  }}
                >
                  {cable_details?.cableInfo?.cable_short_code}
                </h4>
              </Grid>
            </Grid>

            <Grid container style={{ marginTop: 0, marginLeft: 50 }}>
              <Grid item xs={3}>
                <h4 style={{ fontWeight: "500" }}>Cable Type :</h4>
              </Grid>
              <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
                <h4
                  style={{
                    color: "#000",
                    fontWeight: "350",
                  }}
                >
                  {cable_details?.cableInfo?.cable_type}
                </h4>
                {cable_details?.cableInfo?.second_cable_type && (
                  <h4
                    style={{
                      color: "#000",
                      fontWeight: "350",
                    }}
                  >
                    -
                  </h4>
                )}
                <h4
                  style={{ color: "#000", fontWeight: "350", display: "flex" }}
                >
                  {cable_details?.cableInfo?.second_cable_type}
                </h4>
              </Grid>
              <Grid item xs={3}>
                <h4 style={{ fontWeight: "500" }}>End to End Loss :</h4>
              </Grid>
              <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
                <h4
                  style={{
                    color: "#000",
                    fontWeight: "350",
                  }}
                >
                  {`${cable_details?.cableInfo?.loss} dB`}
                </h4>
              </Grid>
            </Grid>
          </div>

          <div style={{ marginTop: 60 }}>
            <Grid
              container
              style={{ marginTop: 160, justifyContent: "space-around" }}
            >
              <Grid item xs={5.2}>
                <h4 style={{ textAlign: "center" }}>
                  Source A{" - "}
                  {!!cable_details &&
                    cable_details?.sourceCableInfo[0]?.station_name}
                  ({" "}
                  {!!cable_details && cable_details?.cableInfo?.source_fms_code}
                  -
                  {!!cable_details && cable_details?.cableInfo?.source_fms_type}
                  )
                </h4>
              </Grid>
              <Grid item xs={5.2}>
                <h4 style={{ textAlign: "center" }}>
                  Source B {" - "}
                  {!!cable_details &&
                    cable_details?.destinationCableInfo[0]?.station_name}
                  ({" "}
                  {!!cable_details &&
                    cable_details?.cableInfo?.destination_fms_code}
                  -
                  {!!cable_details &&
                    cable_details?.cableInfo?.destination_fms_type}
                  ){" "}
                </h4>
              </Grid>
            </Grid>
          </div>
          {[
            ...Array(
              !!cable_details &&
                cable_details?.sourceCableInfo.length >
                  cable_details?.destinationCableInfo.length
                ? cable_details?.sourceCableInfo.length
                : cable_details?.destinationCableInfo.length
            ),
          ].map((item, index) => (
            <Grid
              key={index}
              container
              style={{ marginTop: 20, justifyContent: "space-around" }}
            >
              <Grid item xs={5.2}>
                {!!cable_details &&
                cable_details?.sourceCableInfo.length >= index + 1 ? (
                  <div className="fibre">
                    <h3>Fibre # {index + 1}</h3>

                    <Grid
                      container
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      padding={"0px"}
                      style={{ borderBottom: "1px solid #bfe5f3" }}
                    >
                      <Grid item xs={3}>
                        <p
                          style={{
                            margin: "10px 0px 10px 20px",
                            fontWeight: 500,
                            color: "#1d7697",
                          }}
                        >
                          Tube Color :
                        </p>
                      </Grid>
                      <Grid item xs={1}>
                        {cable_details?.sourceCableInfo[index].tube_colour}
                      </Grid>

                      <Grid item xs={2.5}>
                        <p
                          style={{
                            fontWeight: 500,
                            margin: "10px 0px 10px 0px",
                            color: "#1d7697",
                          }}
                        >
                          Fibre Color :
                        </p>
                      </Grid>
                      <Grid item xs={1}>
                        {cable_details?.sourceCableInfo[index].fibre_colour}
                      </Grid>
                      <Grid item xs={2.5}>
                        <p
                          style={{
                            fontWeight: 500,
                            margin: "10px 0px 10px 0px",
                            color: "#1d7697",
                          }}
                        >
                          Fibre Type :
                        </p>
                      </Grid>
                      <Grid item xs={2}>
                        <p style={{ margin: "10px 0px 10px 0px" }}>
                          {!!cable_details?.sourceCableInfo[index]?.fibre_type
                            ? cable_details?.sourceCableInfo[index]?.fibre_type
                            : "NA"}
                        </p>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      padding={"0px"}
                      style={{ borderBottom: "1px solid #bfe5f3" }}
                    >
                      <Grid item xs={3}>
                        <p
                          style={{
                            margin: "10px 0px 10px 20px",
                            fontWeight: 500,
                            color: "#1d7697",
                          }}
                        >
                          Fibre Status :
                        </p>
                      </Grid>
                      <Grid item xs={2.3}>
                        <p style={{ margin: "10px 0px 10px 0px" }}>
                          {" "}
                          {!!cable_details?.sourceCableInfo[index]?.fibre_status
                            ? cable_details?.sourceCableInfo[index]
                                ?.fibre_status
                            : "NA"}
                        </p>
                      </Grid>

                      <Grid item xs={4}>
                        <p
                          style={{
                            margin: "10px 0px 10px 0px",
                            fontWeight: 500,
                            color: "#1d7697",
                          }}
                        >
                          Terminated Status :
                        </p>
                      </Grid>
                      <Grid item xs={2}>
                        <p style={{ margin: "10px 0px 10px 0px" }}>
                          {" "}
                          {!!cable_details?.sourceCableInfo[index]?.service_type
                            ? cable_details?.sourceCableInfo[index]
                                ?.service_type
                            : "NA"}
                        </p>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      padding={"0px"}
                      style={{ borderBottom: "1px solid #bfe5f3" }}
                    >
                      <Grid item xs={4}>
                        <p
                          style={{
                            margin: "10px 0px 10px 20px",
                            fontWeight: 500,
                            color: "#1d7697",
                          }}
                        >
                          Adapter Type :
                        </p>
                      </Grid>
                      <Grid item xs={2}>
                        <p style={{ margin: "10px 0px 10px 20px" }}>
                          {!!cable_details?.sourceCableInfo[index]?.adapter_type
                            ? cable_details?.sourceCableInfo[index]
                                ?.adapter_type
                            : "NA"}
                        </p>
                      </Grid>
                      <Grid item xs={4}>
                        <p
                          style={{
                            margin: "10px 0px 10px 20px",
                            fontWeight: 500,
                            color: "#1d7697",
                          }}
                        >
                          Adapter Position :
                        </p>
                      </Grid>
                      <Grid item xs={2}>
                        <p style={{ margin: "10px 0px 10px 20px" }}>
                          {" "}
                          {!!cable_details?.sourceCableInfo[index]
                            ?.adapter_position
                            ? cable_details?.sourceCableInfo[index]
                                ?.adapter_position
                            : "NA"}
                        </p>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      padding={"0px"}
                      style={{ borderBottom: "1px solid #bfe5f3" }}
                    >
                      <Grid item xs={6}>
                        <p
                          style={{
                            margin: "10px 0px 10px 20px",
                            fontWeight: 500,
                            color: "#1d7697",
                          }}
                        >
                          {/* {!!cable_details?.sourceCableInfo[index]
                              .fibre_status &&
                            cable_details?.sourceCableInfo[index]
                              .fibre_status === "TERMINATED" &&
                            !!cable_details?.sourceCableInfo[index]
                              .service_type &&
                            cable_details?.sourceCableInfo[index]
                              .service_type === "PATCHING"
                              ? "Patch With :"
                              : "Spliced With :"} */}

                          {!!cable_details?.destinationCableInfo[index]
                            ?.fibre_status &&
                          cable_details?.destinationCableInfo[index]
                            ?.fibre_status === "TERMINATED"
                            ? "Patching-With :"
                            : "Spliced-With :"}
                        </p>
                      </Grid>
                      <Grid item xs={6}>
                        <p style={{ margin: "10px 0px 10px 0px" }}>
                          {" "}
                          {!!cable_details?.sourceCableInfo[index]
                            ?.spliced_fibre_code
                            ? cable_details?.sourceCableInfo[index]
                                ?.spliced_fibre_code
                            : "NA"}
                        </p>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      padding={"0px"}
                      style={{ borderBottom: "1px solid #bfe5f3" }}
                    >
                      <Grid item xs={6}>
                        <p
                          style={{
                            margin: "10px 0px 10px 20px",
                            fontWeight: 500,
                            color: "#1d7697",
                          }}
                        >
                          Service Name :
                        </p>
                      </Grid>
                      <Grid item xs={6}>
                        <p style={{ margin: "10px 0px 10px 0px" }}>
                          {!!cable_details?.sourceCableInfo[index]?.service_name
                            ? cable_details?.sourceCableInfo[index]
                                ?.service_name
                            : "NA"}
                        </p>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      padding={"0px"}
                      style={{ borderBottom: "1px solid #bfe5f3" }}
                    >
                      <Grid item xs={6}>
                        <p
                          style={{
                            margin: "10px 0px 10px 20px",
                            fontWeight: 500,
                            color: "#1d7697",
                          }}
                        >
                          Service Type :
                        </p>
                      </Grid>
                      <Grid item xs={6}>
                        <p style={{ margin: "10px 0px 10px 0px" }}>
                          {!!cable_details?.sourceCableInfo[index]
                            ?.service_impl_type
                            ? cable_details?.sourceCableInfo[index]
                                ?.service_impl_type
                            : "NA"}
                        </p>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      padding={"0px"}
                      style={{ borderBottom: "1px solid #bfe5f3" }}
                    >
                      <Grid item xs={6}>
                        <p
                          style={{
                            margin: "10px 0px 10px 20px",
                            fontWeight: 500,
                            color: "#1d7697",
                          }}
                        >
                          Service Description :
                        </p>
                      </Grid>
                      <Grid item xs={6}>
                        <p style={{ margin: "10px 0px 10px 0px" }}>
                          {!!cable_details?.sourceCableInfo[index]?.service_desc
                            ? cable_details?.sourceCableInfo[index]
                                ?.service_desc
                            : "NA"}
                        </p>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      padding={"0px"}
                      style={{ borderBottom: "1px solid #bfe5f3" }}
                    >
                      <Grid item xs={6}>
                        <p
                          style={{
                            margin: "10px 0px 10px 20px",
                            fontWeight: 500,
                            color: "#1d7697",
                          }}
                        >
                          Ferrule Name :
                        </p>
                      </Grid>
                      <Grid item xs={6}>
                        <p style={{ margin: "10px 0px 10px 0px" }}>
                          {!!cable_details?.sourceCableInfo[index]?.ferule_name
                            ? cable_details?.sourceCableInfo[index]?.ferule_name
                            : "NA"}
                        </p>
                      </Grid>
                    </Grid>
                    {!!cable_details?.sourceCableInfo[index]
                      ?.spliced_status && (
                      <Grid
                        container
                        justifyContent={"space-between"}
                        padding={"0px"}
                        style={{ borderBottom: "1px solid #bfe5f3" }}
                      >
                        <Grid item xs={6}>
                          <p
                            style={{
                              margin: "10px 0px 10px 20px",
                              fontWeight: 500,
                              color: "#1d7697",
                            }}
                          >
                            Spliced Status :{" "}
                          </p>
                        </Grid>
                        <Grid item xs={6}>
                          <p style={{ margin: "10px 0px 10px 0px" }}>
                            {!!cable_details?.sourceCableInfo[index]
                              ?.spliced_status
                              ? cable_details?.sourceCableInfo[index]
                                  ?.spliced_status
                              : "NA"}
                          </p>
                        </Grid>
                      </Grid>
                    )}

                    {!!cable_details?.sourceCableInfo[index]?.fms_type &&
                      cable_details?.sourceCableInfo[index]?.fms_type !==
                        "FODB" && (
                        <Grid
                          container
                          justifyContent={"space-between"}
                          padding={"0px"}
                          style={{ borderBottom: "1px solid #bfe5f3" }}
                        >
                          <Grid item xs={6}>
                            <p
                              style={{
                                margin: "10px 0px 10px 20px",
                                fontWeight: 500,
                                color: "#1d7697",
                              }}
                            >
                              FDB Code :{" "}
                            </p>
                          </Grid>
                          <Grid item xs={6}>
                            <p style={{ margin: "10px 0px 10px 0px" }}>
                              {!!cable_details?.sourceCableInfo[index]
                                ?.fdb_fms_code
                                ? cable_details?.sourceCableInfo[index]
                                    ?.fdb_fms_code
                                : "NA"}
                            </p>
                          </Grid>
                        </Grid>
                      )}
                    <Grid
                      container
                      justifyContent={"space-between"}
                      padding={"0px"}
                      style={{ borderBottom: "1px solid #bfe5f3" }}
                    >
                      <Grid item xs={6}>
                        <p
                          style={{
                            margin: "10px 0px 10px 20px",
                            fontWeight: 500,
                            color: "#1d7697",
                          }}
                        >
                          Remarks :
                        </p>
                      </Grid>
                      <Grid item xs={6}>
                        <p style={{ margin: "10px 0px 10px 0px" }}>
                          {!!cable_details?.sourceCableInfo[index]?.remarks
                            ? cable_details?.sourceCableInfo[index]?.remarks
                            : "NA"}
                        </p>
                      </Grid>
                    </Grid>
                  </div>
                ) : (
                  <div className="fibre">
                    <h3>There is no Fibre !</h3>
                    <h1 style={{ color: "#4b90a9" }}>Asymmetric</h1>
                  </div>
                )}
              </Grid>
              <Grid item xs={5.2}>
                {!!cable_details &&
                cable_details?.destinationCableInfo.length >= index + 1 ? (
                  <div className="fibre">
                    <h3>Fibre # {index + 1}</h3>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      padding={"0px"}
                      style={{ borderBottom: "1px solid #bfe5f3" }}
                    >
                      <Grid item xs={3}>
                        <p
                          style={{
                            margin: "10px 0px 10px 20px",
                            fontWeight: 500,
                            color: "#1d7697",
                          }}
                        >
                          Tube Color :
                        </p>
                      </Grid>
                      <Grid item xs={1}>
                        {cable_details?.destinationCableInfo[index].tube_colour}
                      </Grid>

                      <Grid item xs={2.5}>
                        <p
                          style={{
                            margin: "10px 0px 10px 0px",
                            fontWeight: 500,
                            color: "#1d7697",
                          }}
                        >
                          Fibre Color :
                        </p>
                      </Grid>
                      <Grid item xs={1}>
                        {
                          cable_details?.destinationCableInfo[index]
                            .fibre_colour
                        }
                      </Grid>
                      <Grid item xs={2.5}>
                        <p
                          style={{
                            margin: "10px 0px 10px 0px",
                            fontWeight: 500,
                            color: "#1d7697",
                          }}
                        >
                          Fibre Type :
                        </p>
                      </Grid>
                      <Grid item xs={2}>
                        <p style={{ margin: "10px 0px 10px 0px" }}>
                          {!!cable_details?.destinationCableInfo[index]
                            ?.fibre_type
                            ? cable_details?.destinationCableInfo[index]
                                ?.fibre_type
                            : "NA"}
                        </p>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      padding={"0px"}
                      style={{ borderBottom: "1px solid #bfe5f3" }}
                    >
                      <Grid item xs={3}>
                        <p
                          style={{
                            margin: "10px 0px 10px 20px",
                            fontWeight: 500,
                            color: "#1d7697",
                          }}
                        >
                          Fibre Status :
                        </p>
                      </Grid>
                      <Grid item xs={2.3}>
                        <p style={{ margin: "10px 0px 10px 0px" }}>
                          {" "}
                          {!!cable_details?.destinationCableInfo[index]
                            ?.fibre_status
                            ? cable_details?.destinationCableInfo[index]
                                ?.fibre_status
                            : "NA"}
                        </p>
                      </Grid>

                      <Grid item xs={4}>
                        <p
                          style={{
                            margin: "10px 0px 10px 0px",
                            fontWeight: 500,
                            color: "#1d7697",
                          }}
                        >
                          Terminated Status :
                        </p>
                      </Grid>
                      <Grid item xs={2}>
                        <p style={{ margin: "10px 0px 10px 0px" }}>
                          {" "}
                          {!!cable_details?.destinationCableInfo[index]
                            ?.service_type
                            ? cable_details?.destinationCableInfo[index]
                                ?.service_type
                            : "NA"}
                        </p>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      padding={"0px"}
                      style={{ borderBottom: "1px solid #bfe5f3" }}
                    >
                      <Grid item xs={4}>
                        <p
                          style={{
                            margin: "10px 0px 10px 20px",
                            fontWeight: 500,
                            color: "#1d7697",
                          }}
                        >
                          Adapter Type :
                        </p>
                      </Grid>
                      <Grid item xs={2}>
                        <p style={{ margin: "10px 0px 10px 0px" }}>
                          {!!cable_details?.destinationCableInfo[index]
                            ?.adapter_type
                            ? cable_details?.destinationCableInfo[index]
                                ?.adapter_type
                            : "NA"}
                        </p>
                      </Grid>
                      <Grid item xs={4}>
                        <p
                          style={{
                            margin: "10px 0px 10px 0px",
                            fontWeight: 500,
                            color: "#1d7697",
                          }}
                        >
                          Adapter Position :
                        </p>
                      </Grid>
                      <Grid item xs={2}>
                        <p style={{ margin: "10px 0px 10px 0px" }}>
                          {!!cable_details?.destinationCableInfo[index]
                            ?.adapter_position
                            ? cable_details?.destinationCableInfo[index]
                                ?.adapter_position
                            : "NA"}
                        </p>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      justifyContent={"space-between"}
                      padding={"0px"}
                      style={{ borderBottom: "1px solid #bfe5f3" }}
                    >
                      <Grid item xs={6}>
                        <p
                          style={{
                            margin: "10px 0px 10px 20px",
                            fontWeight: 500,
                            color: "#1d7697",
                          }}
                        >
                          {/* {!!cable_details?.destinationCableInfo[index]
                              .fibre_status &&
                            cable_details?.destinationCableInfo[index]
                              .fibre_status === "TERMINATED" &&
                            !!cable_details?.destinationCableInfo[index]
                              .service_type &&
                            cable_details?.destinationCableInfo[index]
                              .service_type === "PATCH-WITH"
                              ? "Patch With :"
                              : "Spliced With :"} */}
                          {!!cable_details?.destinationCableInfo[index]
                            ?.fibre_status &&
                          cable_details?.destinationCableInfo[index]
                            ?.fibre_status === "TERMINATED"
                            ? "Patching-With :"
                            : "Spliced-With :"}
                        </p>
                      </Grid>
                      <Grid item xs={6}>
                        <p style={{ margin: "10px 0px 10px 0px" }}>
                          {!!cable_details?.destinationCableInfo[index]
                            ?.spliced_fibre_code
                            ? cable_details?.destinationCableInfo[index]
                                ?.spliced_fibre_code
                            : "NA"}
                        </p>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      padding={"0px"}
                      style={{ borderBottom: "1px solid #bfe5f3" }}
                    >
                      <Grid item xs={6}>
                        <p
                          style={{
                            margin: "10px 0px 10px 20px",
                            fontWeight: 500,
                            color: "#1d7697",
                          }}
                        >
                          Service Name :
                        </p>
                      </Grid>
                      <Grid item xs={6}>
                        <p style={{ margin: "10px 0px 10px 0px" }}>
                          {!!cable_details?.destinationCableInfo[index]
                            ?.service_name
                            ? cable_details?.destinationCableInfo[index]
                                ?.service_name
                            : "NA"}
                        </p>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      padding={"0px"}
                      style={{ borderBottom: "1px solid #bfe5f3" }}
                    >
                      <Grid item xs={6}>
                        <p
                          style={{
                            margin: "10px 0px 10px 20px",
                            fontWeight: 500,
                            color: "#1d7697",
                          }}
                        >
                          Service Type :
                        </p>
                      </Grid>
                      <Grid item xs={6}>
                        <p style={{ margin: "10px 0px 10px 0px" }}>
                          {!!cable_details?.destinationCableInfo[index]
                            ?.service_impl_type
                            ? cable_details?.destinationCableInfo[index]
                                ?.service_impl_type
                            : "NA"}
                        </p>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      padding={"0px"}
                      style={{ borderBottom: "1px solid #bfe5f3" }}
                    >
                      <Grid item xs={6}>
                        <p
                          style={{
                            margin: "10px 0px 10px 20px",
                            fontWeight: 500,
                            color: "#1d7697",
                          }}
                        >
                          Service Description :
                        </p>
                      </Grid>
                      <Grid item xs={6}>
                        <p style={{ margin: "10px 0px 10px 0px" }}>
                          {!!cable_details?.destinationCableInfo[index]
                            ?.service_desc
                            ? cable_details?.destinationCableInfo[index]
                                ?.service_desc
                            : "NA"}
                        </p>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      padding={"0px"}
                      style={{ borderBottom: "1px solid #bfe5f3" }}
                    >
                      <Grid item xs={6}>
                        <p
                          style={{
                            margin: "10px 0px 10px 20px",
                            fontWeight: 500,
                            color: "#1d7697",
                          }}
                        >
                          Ferrule Name :
                        </p>
                      </Grid>
                      <Grid item xs={6}>
                        <p style={{ margin: "10px 0px 10px 0px" }}>
                          {!!cable_details?.destinationCableInfo[index]
                            ?.ferule_name
                            ? cable_details?.destinationCableInfo[index]
                                ?.ferule_name
                            : "NA"}
                        </p>
                      </Grid>
                    </Grid>
                    {!!cable_details?.destinationCableInfo[index]?.fms_type &&
                      cable_details?.destinationCableInfo[index]?.fms_type !==
                        "FODB" && (
                        <Grid
                          container
                          justifyContent={"space-between"}
                          padding={"0px"}
                          style={{ borderBottom: "1px solid #bfe5f3" }}
                        >
                          <Grid item xs={6}>
                            <p
                              style={{
                                margin: "10px 0px 10px 20px",
                                fontWeight: 500,
                                color: "#1d7697",
                              }}
                            >
                              FDB Code :{" "}
                            </p>
                          </Grid>
                          <Grid item xs={6}>
                            <p style={{ margin: "10px 0px 10px 0px" }}>
                              {!!cable_details?.destinationCableInfo[index]
                                ?.fdb_fms_code
                                ? cable_details?.destinationCableInfo[index]
                                    ?.fdb_fms_code
                                : "NA"}
                            </p>
                          </Grid>
                        </Grid>
                      )}
                    <Grid
                      container
                      justifyContent={"space-between"}
                      padding={"0px"}
                      style={{ borderBottom: "1px solid #bfe5f3" }}
                    >
                      <Grid item xs={6}>
                        <p
                          style={{
                            margin: "10px 0px 10px 20px",
                            fontWeight: 500,
                            color: "#1d7697",
                          }}
                        >
                          Remarks :
                        </p>
                      </Grid>
                      <Grid item xs={6}>
                        <p style={{ margin: "10px 0px 10px 0px" }}>
                          {!!cable_details?.destinationCableInfo[index]?.remarks
                            ? cable_details?.destinationCableInfo[index]
                                ?.remarks
                            : "NA"}
                        </p>
                      </Grid>
                    </Grid>
                  </div>
                ) : (
                  <div className="fibre">
                    <h3>There is no Fibre !</h3>
                    <h1 style={{ color: "#4b90a9" }}>Asymmetric</h1>
                  </div>
                )}
              </Grid>
            </Grid>
          ))}
        </DialogContentText>
      </DialogContent>
      <Grid container justifyContent={"center"} alignItems={"center"}>
        <Grid item>
          <DialogActions style={{}} sx={{ overflow: "hidden" }}>
            <Button onClick={handleClose} variant="contained" color="error">
              Close
            </Button>
            {show_pdf && (
              <Button
                variant="contained"
                sx={{ color: "#fff" }}
                onClick={generatePdf}
              >
                Generate PDF
              </Button>
            )}
          </DialogActions>
        </Grid>
      </Grid>
    </Dialog>
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    cable_details: state.cable.cable_det,
  };
};
const mapDispatchToProps = {
  CableDetailLoadAction,
  removeCableDetailLoadAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(CableTreeDialog);
interface CableTreeProps {
  isOpen: boolean;
  setOpen: any;
  data?: any;
  show_pdf?: any;
  GetCableTreeAction?: any;
  cable_id?: number;
  cable_list?: CableTree;
  CableDetailLoadAction?: any;
  cable_details?: CableDetails;
  Destdetail?: DestinationCableInfo;
  Sourcedetail?: SourceCableInfo;
  removeCableDetailLoadAction?: any;
}
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
