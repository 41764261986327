import { LoadingState } from "../../models/loadingModelss";
import { ApiStatusActionTypes } from "../actions/apiStatusActions";
import { CableActionTypes } from "../actions/cableAction";
import { CableMasterActionTypes } from "../actions/cableMasterActions";
import { FMSLocationActionTypes } from "../actions/fmsLocationAction";
import { StationActionTypes } from "../actions/stationAction";
import { UserActionTypes } from "../actions/userAction";
import { ZoneActionTypes } from "../actions/zoneActions";
import { FMSActionTypes } from "../actions/fmsAction";
import InitialState from "./initialState";
import { ServiceActionTypes } from "../actions/serviceMasterAction";
import { CableTreeTypes } from "../actions/cableTreeAction";
import { DomainActionTypes } from "../actions/domainAction";
import { ConnectivityActionTypes } from "../actions/connectivityAction";
import { serviceOccupencyTypes } from "../actions/serviceOccupencyAction";
import { ReportActionTypes } from "../actions/ReportAction";
const initialState: LoadingState = InitialState.loading;

export default function LoadingReducer(
  state: LoadingState = initialState,
  action: any
) {
  switch (action.type) {
    case ApiStatusActionTypes.Begin_Api_Call:
      return {
        ...state,
        count: state.count + action.payload.count,
        message: action.payload.message,
      };
    case ApiStatusActionTypes.Api_Call_Error:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case UserActionTypes.User_Login_Success:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case ZoneActionTypes.Zone_GetAll_Success:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case ZoneActionTypes.Zone_Add_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case ZoneActionTypes.Zone_Delete_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case ZoneActionTypes.Get_Zone_By_Id_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case ZoneActionTypes.Update_Zone_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case StationActionTypes.Station_GetAll_Success:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case StationActionTypes.Station_Add_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case StationActionTypes.Station_Delete_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case StationActionTypes.Get_Station_By_Id_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case StationActionTypes.Update_Station_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case StationActionTypes.Get_Station_By_ZoneId_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case FMSLocationActionTypes.FMSLocation_GetAll_Success:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case FMSLocationActionTypes.Get_FMSLocation_By_Id_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case FMSLocationActionTypes.Update_FMSLocation_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case FMSLocationActionTypes.FMSLocation_Add_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case FMSLocationActionTypes.FMSLocation_Delete_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case FMSLocationActionTypes.Get_FMSLocation_By_Station_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case CableMasterActionTypes.Get_All_Fibre_Master_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case CableMasterActionTypes.Fibre_Create_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case CableMasterActionTypes.Fibre_Create_Load_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case CableMasterActionTypes.Update_Fibre_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case CableMasterActionTypes.Delete_Fibre_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case CableMasterActionTypes.Get_All_Tube_Master_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case CableMasterActionTypes.Get_All_Cable_Master_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case CableMasterActionTypes.Get_Cable_Detail_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case CableMasterActionTypes.Tube_Create_Load_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case CableMasterActionTypes.Cable_Create_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case CableMasterActionTypes.Get_All_Tube_Master_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case CableMasterActionTypes.Delete_Tube_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case CableMasterActionTypes.Get_Tube_Detail_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case FMSActionTypes.FMS_GetAll_Success:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case CableMasterActionTypes.Update_Fibre_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case CableMasterActionTypes.Delete_Cable_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case CableMasterActionTypes.Tube_Create_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case CableActionTypes.Get_All_Cable_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case CableMasterActionTypes.Update_Tube_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case FMSActionTypes.FMS_Add_Request_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case FMSActionTypes.Get_Station_By_Zone_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case FMSActionTypes.Get_Location_By_Station_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case FMSActionTypes.FMS_Add_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case FMSActionTypes.FMS_Delete_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case FMSActionTypes.Get_FMS_By_Id_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case FMSActionTypes.Update_FMS_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case CableActionTypes.Cable_Create_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case CableActionTypes.Cable_Detail_Get_By_Id_Load_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case CableActionTypes.Cable_Delete_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case ServiceActionTypes.Service_GetAll_Success:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case FMSActionTypes.SHOW_FMS_LOCATION_WISE:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case ServiceActionTypes.Service_Add_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case ServiceActionTypes.Get_Service_By_Id_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case ServiceActionTypes.Update_Service_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case ServiceActionTypes.Service_Delete_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case CableActionTypes.Show_FMS_Wise_Cable:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case CableActionTypes.Cable_Detail_Load_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case CableTreeTypes.Get_Cable_Tree_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case CableActionTypes.Cable_Service_Update_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case CableActionTypes.Fms_Port_Detail_Load_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case CableActionTypes.FMS_Port_Update_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case UserActionTypes.User_Logout_Success:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case DomainActionTypes.Domain_Types_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case CableActionTypes.Cable_color_get_success:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case CableActionTypes.Cable_Update_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case CableActionTypes.Get_Available_Port_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case CableActionTypes.Get_All_Cable_By_FMS:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case ConnectivityActionTypes.Get_Fibre_By_Service:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case ConnectivityActionTypes.Get_Cable_Fibre_By_FMS:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case ConnectivityActionTypes.Get_All_Service:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case ConnectivityActionTypes.Get_Trace_Fibre_List:
    case CableActionTypes.Get_Fdb_By_Fms_Success:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case CableActionTypes.Get_Fdb2_By_Fms_Success:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case serviceOccupencyTypes.Service_occupency_getAll:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case CableActionTypes.Get_Fdb4_By_Fms_Success:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case ReportActionTypes.Get_Report_data_success:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case CableActionTypes.Get_Fdb_Fibre_Success:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case ReportActionTypes.Get_Asset_Data_Success:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case FMSActionTypes.Get_Joint_Info_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case ReportActionTypes.Get_Damage_Fibre_Get_Success:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case ReportActionTypes.Get_Utilz_Cable_Get_Success:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case ReportActionTypes.End_To_End_Fibre_Trace_Success:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case ReportActionTypes.Get_Cable_By_Service_Success:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case ReportActionTypes.Get_Report_By_Cable_Success:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case UserActionTypes.Change_Password_Success:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case ReportActionTypes.Get_Damage_Fibre_Load_Success:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case FMSActionTypes.Fms_Schematic_Get_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case ReportActionTypes.Get_Fibre_By_Cable_Fms:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case ReportActionTypes.End_To_End_Get_Station_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case ReportActionTypes.Service_Wise_Cable_Detail_Load_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case ReportActionTypes.Bulk_Upload_Report_Load_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case ReportActionTypes.Bulk_File_Upload_Success_Action:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case CableActionTypes.End_to_End_Loss_Success:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case CableActionTypes.Neo_4j_Details_Success:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    case CableActionTypes.OFC_Unterminated_Details_Success:
      return { ...state, count: state.count > 0 ? state.count - 1 : 0 };
    default:
      return state;
  }
}
