import { Clear, Filter } from "@mui/icons-material";
import {
  Box,
  Accordion,
  AccordionSummary,
  Button,
  AccordionDetails,
  Grid,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  tableCellClasses,
  styled,
  TableCell,
  TablePagination,
} from "@mui/material";
import pdfMake from "pdfmake/build/pdfmake";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Autocompletes from "../../components/Autocompletes";
import FilterListIcon from "@mui/icons-material/FilterList";
import { ZoneList } from "../../models/zoneModels";
import { FMSLocationList } from "../../models/fmsLocationModel";
import { CableList, EndToEndLossReportResp } from "../../models/cableModel";
import { fibrebycablefms } from "../../models/ReportModel";
import { StationList } from "../../models/stationModel";
import { FMSList } from "../../models/fmsModel";
import pdfFonts from "pdfmake/build/vfs_fonts";
import CableTreeDialog from "../../components/CableTreeDialog";

type EndtoEndLossReportViewProps = {
  allzone: ZoneList[];
  allfmslocation: FMSLocationList[];

  cable_list: CableList[];
  getFibre?: any;
  GetFMSLocationWiseAction?: any;
  getZoneStations?: any;
  fibre: fibrebycablefms[];
  allstation: StationList[];
  allstation2: StationList[];
  allFMS: FMSList[];
  Filters?: any;
  getStationLocation?: any;
  showFmsWiseCableAction?: any;
  end_to_end_loss_report: EndToEndLossReportResp[];
};

const EndtoEndLossReportView = ({
  allzone,
  allfmslocation,
  cable_list,
  getFibre,
  GetFMSLocationWiseAction,
  fibre,
  getZoneStations,
  allstation,
  allstation2,
  allFMS,
  Filters,
  getStationLocation,
  showFmsWiseCableAction,
  end_to_end_loss_report,
}: EndtoEndLossReportViewProps) => {
  const [expanded, setExpanded] = useState<string | false>(false);
    const [isCableTreeOpen, setCableTreeOpen] = useState<boolean>(false);
      const [cableDetailsId, setCableDetailsId] = useState<number | undefined>();
   const handleChange1 =
     (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
       setExpanded(newExpanded ? panel : false);
     };
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
  } = useForm();
  const { errors } = formState;
  const stationFilter = (id: number, n: number) => {
    getZoneStations(id, n);
  };
  const Clear = () => {
    reset();
  };
  const Filter = (props: any) => {
    Filters({
      fibre_impl_id: props.fibre_impl_id ? props.fibre_impl_id : null,
      destination_station_id: props.station_id2 ? props.station_id2 : null,
      cable_detail_id: props?.cable_detail_id ? props?.cable_detail_id : null,
      fms_id: props?.fms_id ? props?.fms_id : null,
      location_id: props?.location_id ? props?.location_id : null,
      station_id: props?.station_id ? props?.station_id : null,
      zone_id: props?.zone_id ? props?.zone_id : null,
    });
    setExpanded(false);
  };
  console.log(allfmslocation);
  const styles = {
    marginRight: "50px",
  };
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState<number>(0);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+e.target.value);
    setPage(0);
  };
  return (
    <>
        <CableTreeDialog
        isOpen={isCableTreeOpen}
        setOpen={setCableTreeOpen}
        cable_id={cableDetailsId}
        show_pdf={true}
      />
    <Box>
      <Accordion expanded={expanded === "panel1"}
        onChange={handleChange1("panel1")}>
        <AccordionSummary>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row-reverse",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              endIcon={<FilterListIcon />}
              sx={{ color: "#fff" }}
              onClick={() => handleChange1("panel1")}
            >
              Filter
            </Button>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <form>
            <Grid container justifyContent="space-around" alignItems="center">
              <Grid item xs={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={4}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography style={{ fontWeight: 550 }}>Zone</Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography>:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name={"zone_id"}
                      defaultValue={""}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocompletes
                          option={allzone}
                          keys={"zone_id"}
                          label={"zone_name"}
                          onBlur={onBlur}
                          onChange={(value: any) => (
                            onChange(value), !!value && stationFilter(+value, 1)
                          )}
                          placeholder={"Zone"}
                          value={value}
                          errors={errors["zone_id"]}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={4}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ fontWeight: 550 }}>
                        Station
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        control={control}
                        name={"station_id"}
                        defaultValue={""}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Autocompletes
                            hasInfo={false}
                            infoEvent={() => {}}
                            option={allstation}
                            keys={"station_id"}
                            label={"station_name"}
                            onBlur={onBlur}
                            onChange={(value: any) => (
                              onChange(value),
                              !!value && getStationLocation(+value)
                            )}
                            placeholder={"Station"}
                            value={value}
                            errors={errors["station_id"]}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-around" alignItems="center">
              <Grid item xs={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={4}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography style={{ fontWeight: 550 }}>
                      Select Location
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    :
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name={"location_id"}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocompletes
                          hasInfo={false}
                          option={allfmslocation}
                          keys={"location_id"}
                          label={"location_name"}
                          onBlur={onBlur}
                          onChange={(value: any) => (
                            onChange(value),
                            !!value && GetFMSLocationWiseAction(+value)
                          )}
                          placeholder={"Select Location"}
                          value={value}
                          errors={errors["location_id"]}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={4}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography style={{ fontWeight: 550 }}>
                      Select FMS
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    :
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name={"fms_id"}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocompletes
                          hasInfo={false}
                          option={allFMS}
                          keys={"fms_id"}
                          label={"fms_code"}
                          onBlur={onBlur}
                          onChange={(value: any) => (
                            onChange(value),
                            !!value && showFmsWiseCableAction(+value),
                            !!value &&
                              !!getValues("cable_detail_id") &&
                              getFibre({
                                cable_detail_id: getValues("cable_detail_id"),
                                fms_id: value,
                              })
                          )}
                          placeholder={"Select FMS"}
                          value={value}
                          errors={errors["fms_id"]}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-around" alignItems="center">
              <Grid item xs={5}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={4}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography style={{ fontWeight: 550 }}>
                      Select Cable
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    :
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name={"cable_detail_id"}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocompletes
                          hasInfo={false}
                          option={cable_list}
                          keys={"cable_detail_id"}
                          label={"cable_short_code"}
                          onBlur={onBlur}
                          onChange={(value: any) => (
                            onChange(value),
                            !!value &&
                              !!getValues("fms_id") &&
                              getFibre({
                                cable_detail_id: value,
                                fms_id: getValues("fms_id"),
                              })
                          )}
                          placeholder={"Select Cable"}
                          value={value}
                          errors={errors["cable_detail_id"]}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5}>
                {/* <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={4}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography style={{ fontWeight: 550 }}>
                      Select Fibre
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    :
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name={"fibre_impl_id"}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocompletes
                          hasInfo={false}
                          option={fibre}
                          keys={"fibre_impl_id"}
                          label={"tube_colour"}
                          extras2={"fibre_colour"}
                          onBlur={onBlur}
                          onChange={(value: any) => onChange(value)}
                          placeholder={"Select Fibre"}
                          value={value}
                          errors={errors["fibre_impl_id"]}
                        />
                      )}
                    />
                  </Grid>
                </Grid> */}
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end ",
                marginTop: "1.5rem",
                marginRight: "3rem",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  color: "#fff",
                  backgroundColor: "#808090",
                }}
                onClick={() => Clear()}
              >
                Clear
              </Button>
              <Button
                variant="contained"
                sx={{
                  color: "#fff",
                  backgroundColor: "#2e7d32",
                  margin: "0 1rem",
                }}
                onClick={handleSubmit(Filter)}
              >
                Show
              </Button>
            </Box>
          </form>
        </AccordionDetails>
      </Accordion>

      <Paper
        elevation={3}
        sx={{
          width: "100%",
          margin: "1rem 0",
          paddingTop: "1rem",
        }}
      >
        <Box sx={{ width: "100%", height: "100%" }}>
          <Box>
            <Typography
              sx={{
                color: "gray",
                textAlign: "center",
                textShadow: "0px 0px 0px #fff, 3px 3px 0px rgba(0,0,0,0.15)",
                fontWeight: 620,
              }}
              variant="h5"
            >
              {" End To End Loss Report"}
            </Typography>
          </Box>
          <TableContainer sx={{ marginTop: "1.4rem" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    style={{
                      borderCollapse: "collapse",
                    }}
                  >
                    Cable Short Code
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      borderCollapse: "collapse",
                    }}
                  >
                    Source Station / FMS code
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      borderCollapse: "collapse",
                    }}
                  >
                    Destination Station / FMS Code
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      borderCollapse: "collapse",
                    }}
                  >
                    Cable Type
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      borderCollapse: "collapse",
                    }}
                  >
                    Cable Actual Length (mt)
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      borderCollapse: "collapse",
                    }}
                  >
                    Available Fiber Count
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      borderCollapse: "collapse",
                    }}
                  >
                    End to End Loss (dB)
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!!end_to_end_loss_report &&
                Array.isArray(end_to_end_loss_report) &&
                end_to_end_loss_report.length > 0 ? (
                  end_to_end_loss_report.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((tData: any, index: number) => (
                    <StyledTableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                    >
                      <StyledTableCell className="cable_code"
                            onClick={() => {
                              setCableDetailsId(tData.cable_detail_id);
                              setCableTreeOpen(true);
                            }}>
                        {tData.cable_short_code}
                      </StyledTableCell>
                      <StyledTableCell>
                        {`${tData.source_station_name}  `} <br />
                        {tData.source_fms_code}
                      </StyledTableCell>
                      <StyledTableCell>
                        {`${tData.destination_station_name}  `} <br />
                        {tData.destination_fms_code}
                      </StyledTableCell>
                      <StyledTableCell>{tData.cable_type}</StyledTableCell>
                      <StyledTableCell>
                        {tData.cable_actual_length}
                      </StyledTableCell>
                      <StyledTableCell>
                        {tData.available_fibre_count}
                      </StyledTableCell>
                      <StyledTableCell>
                        {tData.loss ? tData.loss : "N/A"}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <tr
                    style={{
                      border: "1px solid gray",
                      textAlign: "center",
                    }}
                  >
                    <td
                      colSpan={12}
                      style={{
                        // border: "1px solid gray",
                        borderCollapse: "collapse",
                      }}
                    >
                      No record found
                    </td>
                  </tr>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={styles}
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={end_to_end_loss_report && end_to_end_loss_report.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          {!!end_to_end_loss_report.length && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                my: 1,
                pb: 1,
              }}
            >
              <Button
                variant="contained"
                sx={{ color: "#fff" }}
                onClick={() => {
                  var temp: any[] = [];
                  for (let i = 0; i < end_to_end_loss_report.length; i++) {
                    temp[i] = [
                      end_to_end_loss_report[i].cable_short_code,
                      `${end_to_end_loss_report[i].source_station_name} - ${end_to_end_loss_report[i].source_fms_code}`,

                      `${end_to_end_loss_report[i].destination_station_name} - ${end_to_end_loss_report[i].destination_station_name} `,

                      end_to_end_loss_report[i].cable_type,
                      `${end_to_end_loss_report[i].cable_actual_length}`,
                      `${end_to_end_loss_report[i].available_fibre_count}`,
                      `${
                        end_to_end_loss_report[i].loss
                          ? `${end_to_end_loss_report[i].loss} dB`
                          : "N/A"
                      }`,
                    ];
                  }
                  pdfMake.vfs = pdfFonts.pdfMake.vfs;
                  var dd: any = {
                    pageOrientation: "landscape",
                    content: [
                      { text: "End to End Loss Report", style: "header" },
                      {
                        style: "tableExample",
                        table: {
                          headerRows: 1,
                          body: [
                            [
                              {
                                text: "Cable Short Code ",
                                style: "tableHeader",
                              },
                              {
                                text: "Source Station/FMS Code ",
                                style: "tableHeader",
                              },
                              {
                                text: "Destination Station ",
                                style: "tableHeader",
                              },
                              {
                                text: "Cable Type",
                                style: "tableHeader",
                              },
                              {
                                text: "Cable Actual Length",
                                style: "tableHeader",
                              },
                              {
                                text: "Available Fiber Count",
                                style: "tableHeader",
                              },
                              {
                                text: "End to End Loss(dB)",
                                style: "tableHeader",
                              },
                            ],

                            ...temp,
                          ],
                        },
                        layout: {
                          fillColor: function (rowIndex: any) {
                            return rowIndex % 2 === 0 ? "#CCCCCC" : null;
                          },
                          hLineWidth: function (i: any, node: any) {
                            return i === 0 || i === node.table.body.length
                              ? 2
                              : 1;
                          },
                          vLineWidth: function (i: any, node: any) {
                            return i === 0 || i === node.table.widths.length
                              ? 2
                              : 1;
                          },
                          hLineColor: function (i: any, node: any) {
                            return i === 0 || i === node.table.body.length
                              ? "black"
                              : "gray";
                          },
                          vLineColor: function (i: any, node: any) {
                            return i === 0 || i === node.table.widths.length
                              ? "black"
                              : "gray";
                          },
                          // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                          // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                          // paddingLeft: function(i, node) { return 4; },
                          // paddingRight: function(i, node) { return 4; },
                          // paddingTop: function(i, node) { return 2; },
                          // paddingBottom: function(i, node) { return 2; },
                          // fillColor: function (rowIndex, node, columnIndex) { return null; }
                        },
                      },
                    ],
                    styles: {
                      header: {
                        fontSize: 18,
                        bold: true,
                        margin: [0, 0, 0, 10],
                        color: "#9d56a2",
                      },
                      subheader: {
                        fontSize: 16,
                        bold: true,
                        margin: [0, 10, 0, 5],
                      },
                      tableExample: {
                        margin: [0, 5, 0, 15],
                        fontSize: 12,
                      },
                      tableHeader: {
                        bold: true,
                        fontSize: 12,
                        color: "white",
                        fillColor: "#035779",
                      },
                    },
                    defaultStyle: {
                      // alignment: 'justify'
                    },
                  };

                  pdfMake.createPdf(dd).open();
                }}
              >
                Generate PDF
              </Button>
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
    </>
  );
};

export default EndtoEndLossReportView;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#035779",
    color: theme.palette.common.white,
    padding: 10,
    paddingLeft: 15,
    fontWeight: 500,
    fontSize: 14,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 8,
    paddingLeft: 15,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
