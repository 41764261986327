import FilterListIcon from "@mui/icons-material/FilterList";
import { Component } from "react";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import Autocompletes from "../../components/Autocompletes";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import {
  Box,
  Accordion,
  AccordionSummary,
  Button,
  AccordionDetails,
  Grid,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  tableCellClasses,
  TableCell,
  styled,
} from "@mui/material";
import { ZoneList } from "../../models/zoneModels";
import { StationList } from "../../models/stationModel";
import { FMSLocationList } from "../../models/fmsLocationModel";
import { CableList, FMSList } from "../../models/fmsModel";
import { CableByService, UtilizeCable } from "../../models/ReportModel";
import CableTreeDialog from "../../components/CableTreeDialog";

function ServiceWiseReportView({
  service_list,
  allstation,
  cableByService,
  clear,
  Filters,
}: CableUtilzProps) {
  const [expanded, setExpanded] = useState<string | false>("panel1");
  const handleChange1 =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  const history = useHistory();
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
  } = useForm();
  const { errors } = formState;
  const Filter = (props: any) => {
    props.station_id == "All"
      ? (props.station_id = null)
      : (props.station_id = props.station_id);
    props.service_id == "All"
      ? (props.service_id = null)
      : (props.service_id = props.service_id);

    Filters(props);
    setExpanded(false);
  };
  const [isCableTreeOpen, setCableTreeOpen] = useState<boolean>(false);
  const [cableDetailsId, setCableDetailsId] = useState<number | undefined>();
  const Clear = () => {
    reset();
    clear();
  };
  const allstationnew = [
    ...allstation,
    { station_code: "All", station_id: "All" },
  ];
  const service_list_new = [
    ...service_list,
    { service_name: "All", service_id: "All" },
  ];
  const [FabSatA, SetFabSatA] = useState<string | undefined>();

  return (
    <>
      <CableTreeDialog
        isOpen={isCableTreeOpen}
        setOpen={setCableTreeOpen}
        cable_id={cableDetailsId}
        show_pdf={true}
      />
      <Box>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange1("panel1")}
        >
          <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
            <Box
              sx={{
                display: "flex",
                flexDirection: "row-reverse",
                width: "100%",
              }}
            >
              <Button
                variant="contained"
                endIcon={<FilterListIcon />}
                sx={{ color: "#fff" }}
                onClick={() => handleChange1("panel1")}
              >
                Filter
              </Button>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <form>
              <Grid container justifyContent="space-around" alignItems="center">
                <Grid item xs={5}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid
                        item
                        xs={4}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography style={{ fontWeight: 550 }}>
                          Select Station
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography>:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Controller
                          control={control}
                          name={"station_id"}
                          defaultValue={""}
                          render={({ field: { onBlur, value, onChange } }) => (
                            <Autocompletes
                              hasInfo={false}
                              infoEvent={() => {}}
                              option={allstationnew}
                              keys={"station_id"}
                              label={"station_code"}
                              onBlur={onBlur}
                              onChange={(value: any) => onChange(value)}
                              placeholder={"Station"}
                              value={value}
                              errors={errors["station_id"]}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={5}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={4}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ fontWeight: 550 }}>
                        Select Service
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      :
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        control={control}
                        name={"service_id"}
                        rules={{ required: false }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Autocompletes
                            hasInfo={false}
                            option={service_list_new}
                            keys={"service_id"}
                            label={"service_name"}
                            onBlur={onBlur}
                            onChange={(value: any) => onChange(value)}
                            placeholder={"Service Name"}
                            value={value}
                            errors={errors["service_id"]}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end ",
                  marginTop: "1.5rem",
                  marginRight: "3rem",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    color: "#fff",
                    backgroundColor: "#808090",
                  }}
                  onClick={() => Clear()}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    color: "#fff",
                    backgroundColor: "#2e7d32",
                    margin: "0 1rem",
                  }}
                  onClick={handleSubmit(Filter)}
                >
                  Show
                </Button>
                {!!cableByService && !!cableByService.length && (
                  <Button
                    variant="contained"
                    sx={{ color: "#fff" }}
                    onClick={() => {
                      var temp: any[] = [];
                      for (let i = 0; i < cableByService.length; i++) {
                        temp[i] = [
                          cableByService[i].cable_short_code,
                          cableByService[i].cable_type,
                          cableByService[i].sourec_fms_code,
                          cableByService[i].source_station_name,
                          cableByService[i].des_fms_code,
                          cableByService[i].des_station_name,

                          `${cableByService[i].cable_length} km`,
                          cableByService[i].fibre_count,
                          `${cableByService[i].fibre_total_length} km`,
                          `${cableByService[i].loss ? `${cableByService[i].loss} dB`: 'N/A'}` ,
                        ];
                      }
                      pdfMake.vfs = pdfFonts.pdfMake.vfs;
                      var dd: any = {
                        pageOrientation: "landscape",
                        content: [
                          {
                            text: "Service Wise Cable Detail",
                            style: "header",
                          },
                          {
                            style: "tableExample",
                            table: {
                              headerRows: 1,
                              body: [
                                [
                                  {
                                    text: "Cable Short Code ",
                                    style: "tableHeader",
                                  },
                                  {
                                    text: "Cable Type",
                                    style: "tableHeader",
                                  },
                                  {
                                    text: "FMS Code (Source A) ",
                                    style: "tableHeader",
                                  },
                                  {
                                    text: "Station Name (Source A)",
                                    style: "tableHeader",
                                  },
                                  {
                                    text: "FMS Code (Source B)",
                                    style: "tableHeader",
                                  },
                                  {
                                    text: "Station Name (Source B) ",
                                    style: "tableHeader",
                                  },

                                  {
                                    text: "Fibre km",
                                    style: "tableHeader",
                                  },
                                  {
                                    text: "No Of Fibre",
                                    style: "tableHeader",
                                  },
                                  {
                                    text: "Total Fibre km",
                                    style: "tableHeader",
                                  },
                                  {
                                    text: "End to End Loss(dB)",
                                    style: "tableHeader",
                                  },
                                ],

                                ...temp,
                              ],
                            },
                            layout: {
                              fillColor: function (rowIndex: any) {
                                return rowIndex % 2 === 0 ? "#CCCCCC" : null;
                              },
                              hLineWidth: function (i: any, node: any) {
                                return i === 0 || i === node.table.body.length
                                  ? 2
                                  : 1;
                              },
                              vLineWidth: function (i: any, node: any) {
                                return i === 0 || i === node.table.widths.length
                                  ? 2
                                  : 1;
                              },
                              hLineColor: function (i: any, node: any) {
                                return i === 0 || i === node.table.body.length
                                  ? "black"
                                  : "gray";
                              },
                              vLineColor: function (i: any, node: any) {
                                return i === 0 || i === node.table.widths.length
                                  ? "black"
                                  : "gray";
                              },
                              // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                              // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                              // paddingLeft: function(i, node) { return 4; },
                              // paddingRight: function(i, node) { return 4; },
                              // paddingTop: function(i, node) { return 2; },
                              // paddingBottom: function(i, node) { return 2; },
                              // fillColor: function (rowIndex, node, columnIndex) { return null; }
                            },
                          },
                        ],
                        styles: {
                          header: {
                            fontSize: 17,
                            bold: true,
                            margin: [0, 0, 0, 10],
                            color: "#9d56a2",
                          },
                          subheader: {
                            fontSize: 15,
                            bold: true,
                            margin: [0, 10, 0, 5],
                          },
                          tableExample: {
                            margin: [0, 5, 0, 10],
                            fontSize: 11,
                          },
                          tableHeader: {
                            bold: true,
                            fontSize: 11,
                            color: "white",
                            fillColor: "#035779",
                          },
                        },
                        defaultStyle: {
                          // alignment: 'justify'
                        },
                      };

                      pdfMake.createPdf(dd).open();
                    }}
                  >
                    Generate PDF
                  </Button>
                )}
              </Box>
            </form>
          </AccordionDetails>
        </Accordion>
        <Paper
          elevation={3}
          sx={{
            width: "100%",
            margin: "1rem 0",
            paddingTop: "1rem",
          }}
        >
          <Box sx={{ width: "100%", height: "100%" }}>
            <Box>
              <Typography
                sx={{
                  color: "gray",
                  textAlign: "center",
                  textShadow: "0px 0px 0px #fff, 3px 3px 0px rgba(0,0,0,0.15)",
                  fontWeight: 620,
                }}
                variant="h5"
              >
                {" Service Wise Cable Detail"}
              </Typography>
            </Box>
            <TableContainer sx={{ marginTop: "1.4rem" }}>
              <Table
                stickyHeader
                //   style={{
                //     border: "1px solid gray",
                //     width: "100%",
                //     borderCollapse: "collapse",
                //     marginTop: "1rem",
                //   }}
              >
                <TableHead>
                  <TableRow
                  // style={{
                  //   border: "1px solid gray",
                  //   backgroundColor: "#2B333E",
                  //   color: "#fff",
                  // }}
                  >
                    <StyledTableCell
                      style={{
                        borderCollapse: "collapse",
                      }}
                    >
                      Short Code
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        borderCollapse: "collapse",
                      }}
                    >
                      Cable Type
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        borderCollapse: "collapse",
                      }}
                    >
                      FMS Code (Source A)
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        borderCollapse: "collapse",
                      }}
                    >
                      Station Name (Source A)
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        borderCollapse: "collapse",
                      }}
                    >
                      FMS Code (Source B)
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        borderCollapse: "collapse",
                      }}
                    >
                      Station Name (Source B)
                    </StyledTableCell>

                    <StyledTableCell
                      style={{
                        borderCollapse: "collapse",
                      }}
                    >
                      Fibre km
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        borderCollapse: "collapse",
                      }}
                    >
                      No Of Fibre
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        borderCollapse: "collapse",
                      }}
                    >
                      Total Fibre km
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        borderCollapse: "collapse",
                      }}
                    >
                      End To End Loss
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!!cableByService && cableByService.length > 0 ? (
                    cableByService?.map((tData: any, index: number) => (
                      <StyledTableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                      >
                        <StyledTableCell
                          className="cable_code"
                          onClick={() => {
                            // setCableDetailsId(tData.cable_detail_id);
                            // setCableTreeOpen(true);
                            history.push(
                              `/service-query-report/${tData.cable_detail_id}/${
                                getValues("service_id") || "0"
                              }`
                            );
                          }}
                        >
                          {tData.cable_short_code
                            ? tData.cable_short_code
                            : "NA"}
                        </StyledTableCell>
                        <StyledTableCell>{tData.cable_type}</StyledTableCell>
                        <StyledTableCell>
                          {tData.sourec_fms_code}
                        </StyledTableCell>
                        <StyledTableCell>
                          {tData.source_station_name}
                        </StyledTableCell>
                        <StyledTableCell>{tData.des_fms_code}</StyledTableCell>
                        <StyledTableCell>
                          {tData.des_station_name}
                        </StyledTableCell>

                        <StyledTableCell>
                          {tData.cable_length} km
                        </StyledTableCell>
                        <StyledTableCell>{tData.fibre_count}</StyledTableCell>
                        <StyledTableCell>
                          {tData.fibre_total_length ? `${tData.fibre_total_length} km` : 'N/A'}
                        </StyledTableCell>
                        <StyledTableCell>
                          {tData?.loss ? `${tData?.loss?.toFixed(2)} dB` : 'N/A'}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  ) : (
                    <tr
                      style={{
                        // border: "1px solid gray",
                        textAlign: "center",
                      }}
                    >
                      <td
                        colSpan={9}
                        style={{
                          //   border: "1px solid gray",
                          borderCollapse: "collapse",
                        }}
                      >
                        No record found
                      </td>
                    </tr>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Paper>
      </Box>
    </>
  );
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#035779",
    color: theme.palette.common.white,
    padding: 10,
    paddingLeft: 15,
    fontWeight: 500,
    fontSize: 14,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 8,
    paddingLeft: 15,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export default ServiceWiseReportView;
interface CableUtilzProps {
  service_list: any[];
  allstation: StationList[];
  clear?: any;
  Filters?: any;
  cableByService: CableByService[];
}
