import React, { useEffect } from "react";
import OFCUnterminatedFiberDetailsView from "./OFCUnterminatedFiberDetailsView";
import { StoreState } from "../../models/reduxModels";
import {
  showFmsWiseCableAction,
  EndToEndLossReportDetails,
  OFCUnterminatedDetailsAction,
} from "../../Stores/actions/cableAction";
import { GetFMSLocationWiseAction } from "../../Stores/actions/fmsAction";
import { getStationLocation } from "../../Stores/actions/fmsLocationAction";
import {
  GetFibreByCableFms,
  EndToEndFibreTraceAction,
  GetCableByService,
  GetDamageFibreLoad,
  EndtoEndClearAction,
  EndToEndGetStation,
} from "../../Stores/actions/ReportAction";
import { GetAllZones } from "../../Stores/actions/zoneActions";
import { connect } from "react-redux";
import {
  CableList,
  EndToEndLossReportResp,
  OFCUnterminatedDetailsResp,
} from "../../models/cableModel";
import { FMSLocationList } from "../../models/fmsLocationModel";
import { FMSList } from "../../models/fmsModel";
import { fibrebycablefms } from "../../models/ReportModel";
import { StationList } from "../../models/stationModel";
import { ZoneList } from "../../models/zoneModels";

type OFCUnterminatedFiberProps = {
  GetAllZones?: any;
  allzone: ZoneList[];
  GetFMSLocationWiseAction?: any;
  allfmslocation: FMSLocationList[];
  cable_list: CableList[];
  GetFibreByCableFms?: any;
  fibre: fibrebycablefms[];
  getZoneStations?: any;
  allstation: StationList[];
  allstation2: StationList[];
  EndToEndFibreTraceAction?: any;
  fibresEndToEnd: any;
  getStationLocation?: any;
  allFMS: FMSList[];
  showFmsWiseCableAction?: any;
  GetDamageFibreLoad?: any;
  EndtoEndClearAction?: any;
  EndToEndGetStation?: any;
  EndToEndLossReportDetails?: any;
  OFCUnterminatedDetailsAction?: any;
  ofc_unterminated_details: OFCUnterminatedDetailsResp[];
};

const OFCUnterminatedFiber = ({
  allFMS,
  allfmslocation,
  allstation,
  allstation2,
  allzone,
  cable_list,
  fibre,
  fibresEndToEnd,
  EndToEndFibreTraceAction,
  EndToEndGetStation,
  EndToEndLossReportDetails,
  EndtoEndClearAction,
  GetAllZones,
  GetDamageFibreLoad,
  GetFMSLocationWiseAction,
  GetFibreByCableFms,
  getStationLocation,
  getZoneStations,
  showFmsWiseCableAction,
  OFCUnterminatedDetailsAction,
  ofc_unterminated_details,
}: OFCUnterminatedFiberProps) => {
  const Filters = (props: any) => {
    OFCUnterminatedDetailsAction(props);
  };
  const getFibre = (props: any) => {
    GetFibreByCableFms(props);
  };
  useEffect(() => {
    GetAllZones();
  }, []);
  useEffect(() => {
    let payload: any ={
      fibre_impl_id: null,
      destination_station_id: null,
      cable_detail_id: null,
      fms_id: null,
      location_id: null,
      station_id: null,
      zone_id: null,
    }
    OFCUnterminatedDetailsAction(payload)
  }, [])
  
  return (
    <OFCUnterminatedFiberDetailsView
      allzone={allzone}
      allfmslocation={allfmslocation}
      cable_list={cable_list}
      getFibre={getFibre}
      GetFMSLocationWiseAction={(id: number) =>
        GetFMSLocationWiseAction({ location_id: +id })
      }
      fibre={fibre}
      allstation2={allstation2}
      allstation={allstation}
      allFMS={allFMS}
      getZoneStations={EndToEndGetStation}
      Filters={Filters}
      //   fibresEndToEnd={fibresEndToEnd}
      getStationLocation={getStationLocation}
      showFmsWiseCableAction={showFmsWiseCableAction}
      ofc_unterminated_details={ofc_unterminated_details}
    />
  );
};
const mapStateToProps = (state: StoreState) => {
  return {
    allzone: state.zone.zone_list,
    allfmslocation: state.fmslocation.fmslocation_list,
    cable_list: state.cable.cable_list,
    allstation2: state.station.station_list2,
    allstation: state.station.station_list,
    fibresEndToEnd: state.report.end_to_end_report,
    allFMS: state.fms.fms_list,
    fibre: state.report.fibrebycablefms,
    ofc_unterminated_details: state.cable.ofc_unterminated_details,
  };
};
const mapDispatchToProps = {
  GetAllZones,
  GetFMSLocationWiseAction,
  GetFibreByCableFms,
  EndToEndFibreTraceAction,
  GetCableByService,
  getStationLocation,
  GetDamageFibreLoad,
  EndtoEndClearAction,
  showFmsWiseCableAction,
  EndToEndGetStation,
  EndToEndLossReportDetails,
  OFCUnterminatedDetailsAction,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OFCUnterminatedFiber);
