import {
  Grid,
  Typography,
  Button,
  IconButton,
  Box,
  Tooltip,
  Backdrop,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import React, { useEffect, useState } from "react";
import Iframe from "react-iframe";
import Autocompletes from "../../../components/Autocompletes";
import { Controller, useForm } from "react-hook-form";
import { StationList } from "../../../models/stationModel";
import { connect, useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../../models/reduxModels";
import { GetAllStations } from "../../../Stores/actions/stationAction";
import { useSnackbar } from "notistack";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import { Neo4jDetailsAction } from "../../../Stores/actions/cableAction";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
function RedudantRouteMain({ allstation, GetAllStations }: RedudantRouteProps) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
  } = useForm();
  const { errors } = formState;
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenRouteInfo, setIsOpenRouteInfo] = useState(false);
  useEffect(() => {
    GetAllStations();
  }, []);
  const unsecuredCopyToClipboard = (text: string) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand("copy");
    } catch (err) {
      console.error("Unable to copy to clipboard", err);
    }
    document.body.removeChild(textArea);
  };
  const OnSubmit = async (data: any) => {
    let query = `MATCH (src:STATION {station_name: '${data.station_id}'} ),
    (dest:STATION {station_name: '${data.station_id2}'})
    MATCH p = shortestPath((src)-[*]-(dest))
    return p;`;
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(query);
    } else {
      unsecuredCopyToClipboard(query);
    }
    await enqueueSnackbar("Query copied to clipboard!", {
      variant: "success",
    });
  };
  const fullScreen = () => {
    var elem: any = document.getElementById("myId");
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  };

  useEffect(() => {
    let payload: any = {
      domain_type: "neo_key",
    };
    dispatch(Neo4jDetailsAction(payload) as any);
  }, []);
  const neo4j_details = useSelector(
    (state: StoreState) => state.cable.neo4j_details
  );
  console.log(neo4j_details);
  const CopyUserName = (data: any) => {
    navigator.clipboard.writeText(data);
    enqueueSnackbar("User Name Copied Successfully", {
      variant: "success",
    });
  };
  const CopyPassword = (data: any) => {
    navigator.clipboard.writeText(data);
    enqueueSnackbar("Password Copied Successfully", {
      variant: "success",
    });
  };
  const CopyURL = (data: any) => {
    navigator.clipboard.writeText(data);
    enqueueSnackbar("URL Copied Successfully", {
      variant: "success",
    });
  };
  const handleOpenCredentials = () => {
    setIsOpen(true);
  };
  const handleOpenRouteInfo = () => {
    setIsOpenRouteInfo(true);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    // border: '2px solid #000',
    boxShadow: 24,
    // p: 4,
    borderRadius: 3,
  };
  const styleForInfo = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 850,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 3,
    padding: 3
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <Grid
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: "#ffff",
      }}
    >
      <Grid container flexDirection={"column"}>
        <Grid item>
          <Typography
            style={{ fontWeight: 500, fontSize: 14, color: "#428BCA" }}
          >
            Constract Query
          </Typography>
        </Grid>
        <Grid item>
          <Grid
            container
            flexDirection={"row"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Grid item xs={2} justifyContent={"center"} alignItems={"center"}>
              <Typography style={{ fontWeight: 550, textAlign: "center" }}>
                Source Station A
              </Typography>
            </Grid>
            <Grid item xs={0.5} justifyContent={"center"} alignItems={"center"}>
              <Typography style={{ fontWeight: 550, textAlign: "center" }}>
                :
              </Typography>
            </Grid>
            <Grid item xs={2.5} justifyContent={"center"} alignItems={"center"}>
              <Controller
                control={control}
                name={"station_id"}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <Autocompletes
                    option={allstation}
                    keys={"station_name"}
                    label={"station_name"}
                    onBlur={onBlur}
                    onChange={onChange}
                    placeholder={"Station A"}
                    value={value}
                    errors={errors["station_id"]}
                  />
                )}
              />
            </Grid>
            <Grid item xs={2} justifyContent={"center"} alignItems={"center"}>
              <Typography style={{ fontWeight: 550, textAlign: "center" }}>
                Source Station B
              </Typography>
            </Grid>
            <Grid item xs={0.5} justifyContent={"center"} alignItems={"center"}>
              <Typography style={{ fontWeight: 550, textAlign: "center" }}>
                :
              </Typography>
            </Grid>
            <Grid item xs={2.5} justifyContent={"center"} alignItems={"center"}>
              <Controller
                control={control}
                name={"station_id2"}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <Autocompletes
                    hasInfo={false}
                    infoEvent={() => {}}
                    option={allstation}
                    keys={"station_name"}
                    label={"station_name"}
                    onBlur={onBlur}
                    onChange={onChange}
                    placeholder={"Station B"}
                    value={value}
                    errors={errors["station_id2"]}
                  />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <Grid container justifyContent={"center"} alignItems={"center"}>
                {
                  <Button
                    variant="contained"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#808090",
                      width: 100,
                    }}
                    onClick={handleSubmit(OnSubmit)}
                  >
                    Copy
                  </Button>
                }
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                  onClick={() => fullScreen()}
                >
                  <OpenInFullIcon />
                </IconButton>
                <Tooltip title="Credentials" onClick={handleOpenCredentials}>
                  <ManageAccountsIcon
                    sx={{ fontSize: 21, color: "#003366", cursor: "pointer" }}
                  />
                </Tooltip>
                <Tooltip title="Route Info" onClick={handleOpenRouteInfo}>
                  <InfoIcon
                    sx={{ fontSize: 21, color: "#003366", cursor: "pointer" }}
                  />
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Iframe
        url="https://browser.neo4j.io/"
        width="100%"
        height="100%"
        id="myId"
        className="myClassname"
        position="relative"
      />
      <Modal open={isOpen} onClose={handleClose}>
        <Box sx={style}>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#00AAEE",
                  width: "100%",
                  p: 0.5,
                  borderTopLeftRadius: 3,
                  borderTopRightRadius: 3,
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: 18,
                    fontWeight: "700",
                    textAlign: "center",
                  }}
                >
                  Neo4j Details
                </Typography>
              </Box>
              <Tooltip
                title="Close"
                sx={{
                  position: "absolute",
                  top: 5,
                  right: 10,
                  color: "#fff",
                  cursor: "pointer",
                }}
                onClick={handleClose}
              >
                <CloseIcon />
              </Tooltip>
            </Grid>
          </Grid>
          <Grid
            container
            flexDirection={"row"}
            // justifyContent={"center"}
            alignItems={"center"}
            my={2}
            ml={5}
          >
            <Grid item xs={4}>
              <Typography
                sx={{ fontSize: 16, fontWeight: "600", color: "#003366" }}
              >
                {`User Name : `}
              </Typography>
              <Typography
                sx={{
                  fontSize: 13,
                  fontWeight: "500",
                  color: "#003366",
                  mt: 0.5,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {`${
                  neo4j_details &&
                  neo4j_details.length > 0 &&
                  neo4j_details.find((m: any) => m.domain_code === 1)
                    ?.domain_value
                }`}
                <span style={{ paddingLeft: 15 }}>
                  <Tooltip
                    title="Copy"
                    onClick={() =>
                      CopyUserName(
                        neo4j_details &&
                          neo4j_details.length > 0 &&
                          neo4j_details.find((m: any) => m.domain_code === 1)
                            ?.domain_value
                      )
                    }
                  >
                    <ContentCopyIcon sx={{ fontSize: 15, cursor: "pointer" }} />
                  </Tooltip>
                </span>
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                sx={{ fontSize: 16, fontWeight: "600", color: "#003366" }}
              >
                {`User Password : `}
              </Typography>
              <Typography
                sx={{
                  fontSize: 13,
                  fontWeight: "500",
                  color: "#003366",
                  mt: 0.5,
                }}
              >
                {`${
                  neo4j_details &&
                  neo4j_details.length > 0 &&
                  neo4j_details.find((m: any) => m.domain_code === 2)
                    ?.domain_value
                }`}
                <span style={{ paddingLeft: 15 }}>
                  <Tooltip
                    title="Copy"
                    onClick={() =>
                      CopyPassword(
                        neo4j_details &&
                          neo4j_details.length > 0 &&
                          neo4j_details.find((m: any) => m.domain_code === 2)
                            ?.domain_value
                      )
                    }
                  >
                    <ContentCopyIcon sx={{ fontSize: 15, cursor: "pointer" }} />
                  </Tooltip>
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12} mt={1} mb={1}>
              <Typography
                sx={{ fontSize: 16, fontWeight: "600", color: "#003366" }}
              >
                {`URL : `}
              </Typography>
              <Typography
                sx={{
                  fontSize: 13,
                  fontWeight: "500",
                  color: "#003366",
                  mt: 0.5,
                }}
              >
                {`${
                  neo4j_details &&
                  neo4j_details.length > 0 &&
                  neo4j_details.find((m: any) => m.domain_code === 3)
                    ?.domain_value
                }`}
                <span style={{ paddingLeft: 15 }}>
                  <Tooltip
                    title="Copy"
                    onClick={() =>
                      CopyURL(
                        neo4j_details &&
                          neo4j_details.length > 0 &&
                          neo4j_details.find((m: any) => m.domain_code === 3)
                            ?.domain_value
                      )
                    }
                  >
                    <ContentCopyIcon sx={{ fontSize: 15, cursor: "pointer" }} />
                  </Tooltip>
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal open={isOpenRouteInfo} onClose={()=>setIsOpenRouteInfo(false)}>
        <Box sx={styleForInfo}>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
              }}
            >
              <h3 style={{color: '#2c3e50', textAlign: 'center', borderRadius: 3, padding: 5}}>
                Redundant Route & Shortest Path Selection - Conceptual Overview
              </h3>

              <h5>1. Objective</h5>
              <p style={{padding: 0}}>
                The system is designed to find the most efficient fiber route
                between two stations, ensuring service continuity using
                available spare terminated, unused coiled fibers. It prioritizes
                paths that require minimal splicing or patching, optimizing
                network reliability and maintenance efforts.
              </p>

              <h5>2. How It Works</h5>
              <ul style={{listStyleType: 'disc',
            marginLeft: '20px'}}>
                <li>
                  Each station is equipped with an FMS that connects to the next
                  station via fiber optic cables.
                </li>
                <li>
                  The system searches for spare terminated, unused coiled fibers
                  between stations (excluding damaged or in-service fibers).
                </li>
                <li>
                  The shortest path is determined based on the minimum number of
                  splices or patching points, ensuring optimal connectivity.
                </li>
              </ul>

              <h5>3. Path Calculation Criteria</h5>
              <ul style={{listStyleType: 'disc',
            marginLeft: '20px'}}>
                <li>
                  <strong>Availability:</strong> Only paths with spare
                  terminated, unused coiled fibers are considered.
                </li>
                <li>
                  <strong>Connectivity:</strong> Stations must be physically
                  connected through FMS and cables.
                </li>
                <li>
                  <strong>Optimization:</strong> The system selects a route
                  requiring the least amount of splicing and patching.
                </li>
                <li>
                  <strong>Redundancy:</strong> Alternative routes can be
                  identified if the primary path is unavailable.
                </li>
              </ul>

              <h5>4. Example Query for Finding the Shortest Path</h5>
              <p>
                The following query finds the shortest fiber route from Station
                A to Station D:
              </p>

              <code style={{ backgroundColor: '#f4f4f4',
            padding: '5px',
            borderRadius: '4px',
            display: 'block',
            margin: '10px 0'}}>
                {`MATCH (src:STATION {station_name: 'A'}), 
      (dest:STATION {station_name: 'D'}) 
MATCH p = shortestPath((src)-[*]-(dest))
RETURN p;`}
              </code>
              <p>
                This query returns a path sequence (e.g., A → B → C → D), where
                each link represents a fiber connection with spare terminated,
                unused coiled fibers.
              </p>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Grid>
  );
}

interface RedudantRouteProps {
  allstation: StationList[];
  GetAllStations?: any;
}
const mapStateToProps = (state: StoreState) => {
  return {
    allstation: state.station.station_list,
  };
};
const mapDispatchToProps = {
  GetAllStations,
};
export default connect(mapStateToProps, mapDispatchToProps)(RedudantRouteMain);
