import { baseServiceUrl, environment } from "../environment";
import {
  CableCreate,
  CableMsterList,
  FibreMater,
  TubeCreates,
  TubeMater,
} from "../models/cableMasterModel";
import { AddFMSLocation, FMSLocationList } from "../models/fmsLocationModel";
import { AddStation, StationList } from "../models/stationModel";
import { UserLoginModel } from "../models/userModels";
import { AddZone, ZoneList } from "../models/zoneModels";
import RestService from "./rest";
import { AddFMSModel, FMSList } from "../models/fmsModel";
import {
  CableAdd,
  CableList,
  cableUpdateInt,
  CableUpdates,
  FibreUpdatess,
  fibrebycablePayload,
  FmsPortPayload,
  PortUpdatePayload,
} from "../models/cableModel";
import { AddService, ServiceList } from "../models/serviceMasterModel";
import { CableAddPayload } from "../models/cableAddModel";
import { ServiceWisecableinfopayload } from "../models/ReportModel";

export const serviceClient = new RestService({
  baseURL: baseServiceUrl,
});

export const UserLoginService = (data: UserLoginModel) => {
  return serviceClient.post(environment.url.LoginUrl, data);
};

export const GetAllZone = () => {
  return serviceClient.get(environment.url.GetAllZoneUrl);
};

export const ZoneAddService = (data: AddZone) => {
  return serviceClient.post(environment.url.ZoneAdddUrl, data);
};

export const GetAllStation = () => {
  return serviceClient.get(environment.url.GetAllStationUrl);
};

export const StationAddService = (data: AddStation) => {
  return serviceClient.post(environment.url.StationAdddUrl, data);
};
export const DeleteZone = (data: number) => {
  return serviceClient.get(environment.url.DeleteZoneUrl + "/" + data);
};
export const GetZoneById = (data: number) => {
  return serviceClient.get(environment.url.ZoneGetByIdUrl + "/" + data);
};
export const ZoneEdit = (data: ZoneList) => {
  return serviceClient.post(environment.url.ZoneUpdateUrl, data);
};
// FMSLocation
export const GetAllFMSLocation = () => {
  return serviceClient.get(environment.url.GetAllFMSLocationUrl);
};
export const FMSLocationAddService = (data: AddFMSLocation) => {
  return serviceClient.post(environment.url.FMSLocationAdddUrl, data);
};
export const DeleteFMSLocation = (data: number) => {
  return serviceClient.get(environment.url.DeleteFMSLocationUrl + "/" + data);
};
export const GetFMSLocationById = (data: number) => {
  return serviceClient.get(environment.url.FMSLocationGetByIdUrl + "/" + data);
};
export const FMSLocationEdit = (data: FMSLocationList) => {
  return serviceClient.post(environment.url.FMSLocationUpdateUrl, data);
};
export const DeleteStation = (data: number) => {
  return serviceClient.get(environment.url.StationDeleteUrl + "/" + data);
};

export const GetStationById = (data: number) => {
  return serviceClient.get(environment.url.StationGetByIdUrl + "/" + data);
};
export const StationEdit = (data: StationList) => {
  return serviceClient.post(environment.url.StationUpdateUrl, data);
};
export const GetStationByZoneId = (data: number) => {
  return serviceClient.post(environment.url.GetStationByZoneIdUrl, {
    zone_id: data,
  });
};
export const GetFibreByFmsCableId = (data: number) => {
  return serviceClient.post(environment.url.GetFibreByFmsCableUrl, data);
};
export const GetLocationByStationId = (data: number) => {
  return serviceClient.post(environment.url.GetLocationByStationUrl, {
    station_id: data,
  });
};

export const GetAllMasterFibre = () => {
  return serviceClient.get(environment.url.FibreMasterListUrl);
};
export const MasterFibreCreate = (data: CableCreate) => {
  return serviceClient.post(environment.url.FibreAddUrl, data);
};
export const FibreCreateLoad = () => {
  return serviceClient.get(environment.url.GetColorCodeUrl);
};
export const FibreUpdate = (data: FibreMater) => {
  return serviceClient.post(environment.url.FibreUpdateUrl, data);
};
export const DeleteFibre = (data: number) => {
  return serviceClient.get(environment.url.DeleteFibreUrl + "/" + data);
};
export const GetAllMasterTube = () => {
  return serviceClient.get(environment.url.GetAllTubeUrl);
};
export const MasterTubeCreate = (data: TubeCreates) => {
  return serviceClient.post(environment.url.TubeAddUrl, data);
};
export const TubeCreateLoad = () => {
  return serviceClient.get(environment.url.FibreMasterListUrl);
};
export const GetAllMasterCable = () => {
  return serviceClient.get(environment.url.GetAllMasterCableUrl);
};
export const GetCableDetail = (data: number) => {
  return serviceClient.get(environment.url.GetCableDetailUrl + "/" + data);
};
export const TubeUpdate = (data: TubeMater) => {
  return serviceClient.post(environment.url.TubeUpdateUrl, data);
};
export const CableCreateService = (data: CableCreate) => {
  return serviceClient.post(environment.url.CableCreateUrl, data);
};
export const DeleteTube = (data: number) => {
  return serviceClient.get(environment.url.DeleteTubeUrl + "/" + data);
};
export const GetTubeDetail = (data: number) => {
  return serviceClient.get(environment.url.GetTubeDetailUrl + "/" + data);
};

export const GetAllFMS = () => {
  return serviceClient.get(environment.url.GetAllFMSUrl);
};
export const FMSAddService = (data: AddFMSModel) => {
  return serviceClient.post(environment.url.FMSAddUrl, data);
};
export const DeleteFMS = (data: number) => {
  return serviceClient.get(environment.url.DeleteFMSUrl + "/" + data);
};
export const GetFMSById = (data: number) => {
  return serviceClient.get(environment.url.GetFMSById + "/" + data);
};
export const FMSEditService = (data: FMSList) => {
  return serviceClient.post(environment.url.editFMS, data);
};
export const EditCable = (data: CableMsterList) => {
  return serviceClient.post(environment.url.editCableUrl, data);
};
export const DeleteCable = (data: number) => {
  return serviceClient.get(environment.url.cableDeleteUrl + "/" + data);
};
export const CableGetAll = () => {
  return serviceClient.get(environment.url.CableGetAllUrl);
};
export const CableDetailCreate = (data: CableAdd) => {
  return serviceClient.post(environment.url.CableDetailCreateUrl, data);
};

export const CableDetailGetById = (data: number) => {
  return serviceClient.get(environment.url.CableDetailSingleUrl + "/" + data);
};
export const CableDelete = (data: number) => {
  return serviceClient.get(environment.url.DeleteCableUrl + "/" + data);
};
export const CableUpdate = (data: cableUpdateInt) => {
  return serviceClient.post(environment.url.UpdateCableUrl, data);
};
export const GetAllService = () => {
  return serviceClient.get(environment.url.GetAllServiceUrl);
};
export const GetFMSByLocation = (data: any) => {
  return serviceClient.post(environment.url.GetFMSByLocation, data);
};
export const ServiceAddService = (data: AddService) => {
  return serviceClient.post(environment.url.ServiceAdddUrl, data);
};
export const GetServicesById = (data: number) => {
  return serviceClient.get(environment.url.ServiceGetByIdUrl + "/" + data);
};

export const ServiceEdit = (data: ServiceList) => {
  return serviceClient.post(environment.url.ServiceUpdateUrl, data);
};
export const DeleteService = (data: number) => {
  return serviceClient.get(environment.url.DeleteServiceUrl + "/" + data);
};
export const CableDetailService = (data: number) => {
  return serviceClient.get(environment.url.CableDetailUrl + "/" + data);
};
export const ServiceWiseCableDetailService = (
  data: ServiceWisecableinfopayload
) => {
  return serviceClient.post(environment.url.ServiceWiseCableDetailUrl, data);
};
export const ShowFMSWiseCable = (data: number) => {
  return serviceClient.get(environment.url.Show_FMS_Wise_Cable + "/" + data); /// editable
};
export const ShowCableTree = (data: number) => {
  return serviceClient.get(environment.url.Show_cable_Tree + "/" + data);
};
export const CableUpdateService = (data: FibreUpdatess) => {
  return serviceClient.post(environment.url.CableServiceUpdate, data);
};

export const FMSPortGet = (data: FmsPortPayload) => {
  return serviceClient.post(environment.url.FmsPortDetailUrl, data);
};

export const FMSPortMapping = (data: PortUpdatePayload) => {
  return serviceClient.post(environment.url.FmsPortMappingUrl, data);
};
export const DomainType = (data: object) => {
  return serviceClient.post(environment.url.DomainTypeUrl, data);
};

export const cableAddService = (data: CableAddPayload) => {
  return serviceClient.post(environment.url.cableAddInitialUrl, data);
};

export const fibrebycablefms = (data: fibrebycablePayload) => {
  return serviceClient.post(environment.url.fibrebycablefmsUrl, data);
};

export const CableDuplicateCheck = (data: string) => {
  return serviceClient.post(environment.url.CableDuplicateCheckUrl, {
    cable_short_code: data,
  });
};

export const GetAvailablePort = (data: number, type: string) => {
  return serviceClient.post(environment.url.getAvailablePortUrl, {
    fms_id: data,
    fms_type: type,
  });
};
export const getAllFMSbyStationIdService = (data: number) => {
  return serviceClient.post(environment.url.getAllFMSbyStationIDURL, {
    station_id: data,
  }); /////     its editable
};

export const getCableByFibreFMSIdService = (data: number) => {
  return serviceClient.post(environment.url.getCableByFibreFMS, {
    fms_id: data,
  });
};

export const getFibreByService = (data: number) => {
  return serviceClient.post(environment.url.getFibreByService, {
    service_id: data,
  });
};
export const GetTraceFibre = (data: {
  sourceFibre: number;
  destinationFibre: number;
}) => {
  return serviceClient.post(environment.url.getTraceFibre, {
    sourceFibre: data.sourceFibre,
    destinationFibre: data.destinationFibre,
  });
};

export const getFdbByFms = (data: number) => {
  return serviceClient.post(environment.url.GetFdbByFmsUrl, {
    fms_id: data,
  });
};

export const GetServiceOccupency = () => {
  return serviceClient.get(environment.url.GetServiceOccupencyUrl);
};

export const GetReportService = (
  transaction_type: string,
  transaction_id: number,
  event_name: string | null,
  start_date: any,
  end_date: any
) => {
  return serviceClient.post(environment.url.GetReportUrl, {
    transaction_type: transaction_type,
    transaction_id: transaction_id,
    event_name: event_name ? event_name.toUpperCase() : event_name,
    start_date: start_date,
    end_date: end_date,
  });
};

export const FDBFMSFab = (data: number, type: string) => {
  return serviceClient.post(environment.url.FDBFMSFabUrl, {
    fdb_fms_id: data,
    fms_type: type,
  });
};

export const getAllAssetVal = (data: any) => {
  return serviceClient.post(environment.url.getAllAssetValueURL, data);
};

export const getAllFDBByFMS = (data: number) => {
  return serviceClient.post(environment.url.getAllFDBByFMS, { fms_id: data });
};
export const getJointInfo = (data: number) => {
  return serviceClient.post(environment.url.GetJointInfoUrl, { fms_id: data });
};
export const DamageFibreService = (data: any) => {
  return serviceClient.post(environment.url.DamageFibreGet, data);
};
export const UtilzCableService = (data: any) => {
  return serviceClient.post(environment.url.UtilzCableGet, data);
};
export const EndToEndFibreTraceService = (data: any) => {
  return serviceClient.post(environment.url.EndToEndFibreTraceUrl, data);
};
export const CableByServiceService = (data: any) => {
  return serviceClient.post(environment.url.CableByServiceGet, data);
};
export const CableWiseReportService = (data: any) => {
  return serviceClient.post(environment.url.CableWiseReportGet, data);
};
export const ChangePassword = (data: any) => {
  return serviceClient.post(environment.url.changePasswordUrl, data);
};
export const GetCableTypetemplate = (data: string) => {
  return serviceClient.post(environment.url.getcableTypeTemplateUrl, {
    cable_type: data,
  });
};
export const GetFmsSchematic = (data: number) => {
  return serviceClient.post(environment.url.GetSchematicFmsUrl, {
    fms_id: data,
  });
};
export const BulkReportList = () => {
  return serviceClient.get(environment.url.BulkReportListUrl);
};
export const BulkFileUpload = (data: any) => {
  return serviceClient.post(environment.url.BulkFileUploadUrl, data);
};
export const EndtoEndLossReportCableWise = (data: any) => {
  return serviceClient.post(environment.url.EndToEndLossFromCableUtilization, data);
};
export const Neo4jDetails = (data: any) => {
  return serviceClient.post(environment.url.Neo4jDetailsFromDomain, data);
};
export const OFCUnterminatedDetailsService = (data: any) => {
  return serviceClient.post(environment.url.OFCUnterminatedFiberDetails, data);
};
export const ReportDownload = (data: number) => {
  return serviceClient.post(environment.url.DownloadReportUrl, {
    bulk_upload_detail_id: data,
  });
};
