import FilterListIcon from "@mui/icons-material/FilterList";
import { Component } from "react";
import { Controller, useForm } from "react-hook-form";
import React, { useState } from "react";
import Autocompletes from "../../components/Autocompletes";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {
  Box,
  Accordion,
  AccordionSummary,
  Button,
  AccordionDetails,
  Grid,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  tableCellClasses,
  useTheme,
  TableCell,
  styled,
  TablePagination,
  TextField,
} from "@mui/material";
import { ZoneList } from "../../models/zoneModels";
import { StationList } from "../../models/stationModel";
import { FMSLocationList } from "../../models/fmsLocationModel";
import { CableList, FMSList } from "../../models/fmsModel";
import { UtilizeCable } from "../../models/ReportModel";
import CableTreeDialog from "../../components/CableTreeDialog";

function CableUtilizationView({
  getZoneStations,
  getStationLocation,
  GetFMSLocationWiseAction,
  showFmsWiseCableAction,
  allzone,
  allstation,
  allfmslocation,
  allFMS,
  cable_list,
  Filters,
  clear,
  utilizeCable,
}: CableUtilzProps) {
  const [expanded, setExpanded] = useState<string | false>(false);
  const handleChange1 =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  const styles = {
    marginRight: "50px",
  };
  const [utilizeCable1, setRows] = useState<UtilizeCable[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState<number>(0);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+e.target.value);
    setPage(0);
  };
  const [isCableTreeOpen, setCableTreeOpen] = useState<boolean>(false);
  const [cableDetailsId, setCableDetailsId] = useState<number | undefined>();
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    watch
  } = useForm();
  const stationFilter = (id: number) => {
    getZoneStations(id);
  };
  const { errors } = formState;
  const Filter = (props: any) => {
    Filters(props);
    setExpanded(false);
  };
  const Clear = () => {
    reset();
    clear();
  };
  const theme = useTheme();
  const utilizationFrom = watch("utilization_from");
const utilizationTo = watch("utilization_to");
  return (
    <>
      <CableTreeDialog
        isOpen={isCableTreeOpen}
        setOpen={setCableTreeOpen}
        cable_id={cableDetailsId}
        show_pdf={true}
      />
      <Box>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange1("panel1")}
        >
          <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
            <Box
              sx={{
                display: "flex",
                flexDirection: "row-reverse",
                width: "100%",
              }}
            >
              <Button
                variant="contained"
                endIcon={<FilterListIcon />}
                sx={{ color: "#fff" }}
                onClick={() => handleChange1("panel1")}
              >
                Filter
              </Button>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <form>
              <Grid container justifyContent="space-around" alignItems="center">
                <Grid item xs={5}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={4}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ fontWeight: 550 }}>Zone</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        control={control}
                        name={"zone_id"}
                        defaultValue={""}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Autocompletes
                            option={allzone}
                            keys={"zone_id"}
                            label={"zone_name"}
                            onBlur={onBlur}
                            onChange={(value: any) => (
                              onChange(value), !!value && stationFilter(+value)
                            )}
                            placeholder={"Zone"}
                            value={value}
                            errors={errors["zone_id"]}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={5}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid
                        item
                        xs={4}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography style={{ fontWeight: 550 }}>
                          Station
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography>:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Controller
                          control={control}
                          name={"station_id"}
                          defaultValue={""}
                          render={({ field: { onBlur, value, onChange } }) => (
                            <Autocompletes
                              hasInfo={false}
                              infoEvent={() => {}}
                              option={allstation}
                              keys={"station_id"}
                              label={"station_code"}
                              onBlur={onBlur}
                              onChange={(value: any) => (
                                onChange(value),
                                !!value && getStationLocation(+value)
                              )}
                              placeholder={"Station"}
                              value={value}
                              errors={errors["station_id"]}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container justifyContent="space-around" alignItems="center">
                <Grid item xs={5}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={4}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ fontWeight: 550 }}>
                        Select Location
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      :
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        control={control}
                        name={"location_id"}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Autocompletes
                            hasInfo={false}
                            option={allfmslocation}
                            keys={"location_id"}
                            label={"location_name"}
                            onBlur={onBlur}
                            onChange={(value: any) => (
                              onChange(value),
                              !!value && GetFMSLocationWiseAction(+value)
                            )}
                            placeholder={"Select Location"}
                            value={value}
                            errors={errors["location_id"]}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={5}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={4}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ fontWeight: 550 }}>
                        Select FMS
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      :
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        control={control}
                        name={"fms_id"}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Autocompletes
                            hasInfo={false}
                            option={allFMS}
                            keys={"fms_id"}
                            label={"fms_code"}
                            onBlur={onBlur}
                            onChange={(value: any) => (
                              onChange(value),
                              !!value && showFmsWiseCableAction(+value)
                            )}
                            placeholder={"Select FMS"}
                            value={value}
                            errors={errors["fms_id"]}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container justifyContent="space-around" alignItems="center">
                <Grid item xs={5}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={4}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ fontWeight: 550 }}>
                        Select Cable
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      :
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        control={control}
                        name={"cable_detail_id"}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Autocompletes
                            hasInfo={false}
                            option={cable_list}
                            keys={"cable_detail_id"}
                            label={"cable_short_code"}
                            onBlur={onBlur}
                            onChange={(value: any) => onChange(value)}
                            placeholder={"Select Cable"}
                            value={value}
                            errors={errors["cable_detail_id"]}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={5}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={4}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ fontWeight: 550 }}>
                        {`Occupency % (>=)`}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      :
                    </Grid>
                    <Grid item xs={6}>
                    <TextField
                        {...register("utilization_from", {
                          required: false,
                          validate: (value) =>
                            value === "" || value === undefined || value === null ||
                            (parseFloat(value) > 0 && parseFloat(value) <= 100) ||
                            "Value must be between 1 and 100",
                        })}
                        // inputProps={{ readOnly: true }}
                        style={{ width: "100%", margin: "0 0 0 5px" }}
                        type="number"
                        // disabled={!!cable_det && !update}
                        variant="outlined"
                        size="small"
                        name="utilization_from"
                        placeholder="Occupency From"
                        error={!!errors["utilization_from"]}
                        helperText={errors.utilization_from?.message}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container justifyContent="space-around" alignItems="center">
                <Grid item xs={5}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={4}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography style={{ fontWeight: 550 }}>
                      {`Occupency % (<=)`}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      :
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        {...register("utilization_to", {
                          required: false,
                          validate: (value) => {
                            if (value === "" || value === undefined || value === null) return true;
                            const parsedValue = parseFloat(value);
                            if (!(parsedValue > 0 && parsedValue <= 100)) {
                              return "Value must be between 1 and 100";
                            }
                            if (utilizationFrom && parsedValue < parseFloat(utilizationFrom)) {
                              return "Must be greater than Occupency % (>=)";
                            }
                            return true;
                          },
                        })}
                        // inputProps={{ readOnly: true }}
                        style={{ width: "100%", margin: "0 0 0 5px" }}
                        type="text"
                        // disabled={!!cable_det && !update}
                        variant="outlined"
                        size="small"
                        name="utilization_to"
                        placeholder="Occupency To"
                        error={!!errors["utilization_to"]}
                        helperText={errors.utilization_to?.message}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={5}></Grid>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end ",
                  marginTop: "1.5rem",
                  marginRight: "3rem",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    color: "#fff",
                    backgroundColor: "#808090",
                  }}
                  onClick={() => Clear()}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    color: "#fff",
                    backgroundColor: "#2e7d32",
                    margin: "0 1rem",
                  }}
                  onClick={handleSubmit(Filter)}
                >
                  Show
                </Button>

                {!!utilizeCable && !!utilizeCable.length && (
                  <Button
                    variant="contained"
                    sx={{ color: "#fff" }}
                    onClick={() => {
                      var temp: any[] = [];
                      for (let i = 0; i < utilizeCable.length; i++) {
                        temp[i] = [
                          utilizeCable[i].cable_short_code,
                          utilizeCable[i].cable_type,
                          utilizeCable[i].source_fms_code,
                          utilizeCable[i].source_station_name,
                          utilizeCable[i].destination_fms_code,
                          utilizeCable[i].destination_station_name,
                          utilizeCable[i].asymmetric_flag == 0
                            ? `${utilizeCable[i].available_fibre_count}
                         ${utilizeCable[i].available_percentage} % `
                            : "",
                          utilizeCable[i].asymmetric_flag == 0
                            ? `${utilizeCable[i].utilization_fibre_count}  
                         ${utilizeCable[i].utilization_percentage}% `
                            : "",
                          `${utilizeCable[i].cable_actual_length} mt`,
                          `${utilizeCable[i].loss ? `${utilizeCable[i].loss} dB`:'N/A'}`,
                        ];
                      }
                      pdfMake.vfs = pdfFonts.pdfMake.vfs;
                      var dd: any = {
                        pageOrientation: "landscape",
                        content: [
                          {
                            text: "Cable Utilization Details",
                            style: "header",
                          },
                          {
                            style: "tableExample",
                            table: {
                              headerRows: 1,
                              widths: ["10%", "10%", "10%", "12%", "10%", "12%", "10%", "10%", "8%", "8%"], // Optimized column widths
                              body: [
                                [
                                  { text: "Cable Short Code", style: "tableHeader" },
                                  { text: "Cable Type", style: "tableHeader" },
                                  { text: "FMS Code (Source A)", style: "tableHeader" },
                                  { text: "Station Name (Source A)", style: "tableHeader" },
                                  { text: "FMS Code (Source B)", style: "tableHeader" },
                                  { text: "Station Name (Source B)", style: "tableHeader" },
                                  { text: "Available Fibre", style: "tableHeader" },
                                  { text: "Utilized Fibre", style: "tableHeader" },
                                  { text: "Cable Length", style: "tableHeader" },
                                  { text: "End to End Loss (dB)", style: "tableHeader" },
                                ],
                                ...temp,
                              ],
                            },
                            layout: {
                              dontBreakRows: true,
                              fillColor: function (rowIndex: any) {
                                return rowIndex % 2 === 0 ? "#CCCCCC" : null;
                              },
                              hLineWidth: function (i: any, node: any) {
                                return i === 0 || i === node.table.body.length
                                  ? 2
                                  : 1;
                              },
                              vLineWidth: function (i: any, node: any) {
                                return i === 0 || i === node.table.widths.length
                                  ? 2
                                  : 1;
                              },
                              hLineColor: function (i: any, node: any) {
                                return i === 0 || i === node.table.body.length
                                  ? "black"
                                  : "gray";
                              },
                              vLineColor: function (i: any, node: any) {
                                return i === 0 || i === node.table.widths.length
                                  ? "black"
                                  : "gray";
                              },
                              // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                              // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                              // paddingLeft: function(i, node) { return 4; },
                              // paddingRight: function(i, node) { return 4; },
                              // paddingTop: function(i, node) { return 2; },
                              // paddingBottom: function(i, node) { return 2; },
                              // fillColor: function (rowIndex, node, columnIndex) { return null; }
                            },
                          },
                        ],
                        styles: {
                          header: {
                            fontSize: 14,
                            bold: true,
                            margin: [0, 0, 0, 10],
                            color: "#9d56a2",
                          },
                          tableExample: {
                            margin: [0, 5, 0, 8],
                            fontSize: 8, // Reduced font size to fit data
                          },
                          tableHeader: {
                            bold: true,
                            fontSize: 9,
                            color: "white",
                            fillColor: "#035779",
                          },
                        },
                        defaultStyle: {
                          // alignment: 'justify'
                        },
                      };

                      pdfMake.createPdf(dd).open();
                    }}
                  >
                    Generate PDF
                  </Button>
                )}
              </Box>
            </form>
          </AccordionDetails>
        </Accordion>
        <Paper
          elevation={3}
          sx={{
            width: "100%",
            margin: "1rem 0",
            paddingTop: "1rem",
          }}
        >
          <Box sx={{ width: "100%", height: "100%" }}>
            <Box>
              <Typography
                sx={{
                  color: "gray",
                  textAlign: "center",
                  textShadow: "0px 0px 0px #fff, 3px 3px 0px rgba(0,0,0,0.15)",
                  fontWeight: 620,
                }}
                variant="h5"
              >
                {"Cable Utilization Detail"}
              </Typography>
            </Box>
            <TableContainer sx={{ marginTop: "1.4rem" }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      style={{
                        borderCollapse: "collapse",
                      }}
                    >
                      Cable Short Code
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        borderCollapse: "collapse",
                      }}
                    >
                      Cable Type
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        borderCollapse: "collapse",
                      }}
                    >
                      FMS Code (Source A)
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        borderCollapse: "collapse",
                      }}
                    >
                      Station Name (Source A)
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        borderCollapse: "collapse",
                      }}
                    >
                      FMS Code (Source B)
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        borderCollapse: "collapse",
                      }}
                    >
                      Station Name (Source B)
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        borderCollapse: "collapse",
                      }}
                    >
                      Available Fibre
                    </StyledTableCell>
                    {/* <StyledTableCell
                  style={{
                    border: "1px solid gray",
                    borderCollapse: "collapse",
                    fontSize: "15px",
                    fontWeight: "500",
                  }}
                >
                  Available %
                </StyledTableCell> */}
                    <StyledTableCell
                      style={{
                        borderCollapse: "collapse",
                      }}
                    >
                      Utilized Fibre
                    </StyledTableCell>
                    {/* <StyledTableCell
                  style={{
                    border: "1px solid gray",
                    borderCollapse: "collapse",
                    fontSize: "15px",
                    fontWeight: "500",
                  }}
                >
                  Utilize %
                </StyledTableCell> */}
                    <StyledTableCell
                      style={{
                        borderCollapse: "collapse",
                      }}
                    >
                      Cable Length
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        borderCollapse: "collapse",
                      }}
                    >
                      End to End Loss
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* {!!utilizeCable && utilizeCable.length > 0 ? (
                    utilizeCable?.map((tData: UtilizeCable, index: number) => ( */}
                  {!!utilizeCable &&
                    utilizeCable
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((tData, index) => (
                        <StyledTableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                        >
                          <StyledTableCell
                            className="cable_code"
                            onClick={() => {
                              setCableDetailsId(tData.cable_detail_id);
                              setCableTreeOpen(true);
                            }}
                          >
                            {tData.cable_short_code
                              ? tData.cable_short_code
                              : "NA"}
                          </StyledTableCell>
                          <StyledTableCell>{tData.cable_type}</StyledTableCell>
                          <StyledTableCell>
                            {tData.source_fms_code}
                          </StyledTableCell>
                          <StyledTableCell>
                            {tData.source_station_name}
                          </StyledTableCell>
                          <StyledTableCell>
                            {tData.destination_fms_code}
                          </StyledTableCell>
                          <StyledTableCell>
                            {tData.destination_station_name}
                          </StyledTableCell>
                          <StyledTableCell>
                            {tData.asymmetric_flag
                              ? ``
                              : `${tData.available_fibre_count} \n
                        (${tData.available_percentage}%)`}

                            {/* {tData.available_fibre_count} <br />(
                      {tData.available_percentage}) */}
                          </StyledTableCell>
                          {/* <td
                      style={{
                        border: "1px solid gray",
                        borderCollapse: "collapse",
                        fontSize: "13px",
                        
                      }}
                    >
                      {tData.available_percentage}
                    </td> */}
                          <StyledTableCell>
                            {tData.asymmetric_flag
                              ? ``
                              : `${tData.utilization_fibre_count} \n
                        (${tData.utilization_percentage}%)`}

                            {/* {tData.utilization_fibre_count} <br /> (
                      {tData.utilization_percentage} %) */}
                          </StyledTableCell>
                          {/* <td
                      style={{
                        border: "1px solid gray",
                        borderCollapse: "collapse",
                        fontSize: "13px",
                        
                      }}
                    >
                      {tData.utilization_percentage}
                    </td> */}

                          <StyledTableCell>
                            {tData.cable_actual_length} mt
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              borderCollapse: "collapse",
                            }}
                          >
                            {tData?.loss
                              ? `${tData?.loss?.toFixed(2)} dB`
                              : "N/A"}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}{" "}
                  {utilizeCable.length == 0 && (
                    <StyledTableRow
                      style={{
                        // border: "1px solid gray",
                        textAlign: "center",
                      }}
                    >
                      <StyledTableCell
                        colSpan={8}
                        style={{
                          //   border: "1px solid gray",
                          borderCollapse: "collapse",
                        }}
                      >
                        No record found
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              style={styles}
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={utilizeCable.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </Paper>
      </Box>
    </>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#035779",
    color: theme.palette.common.white,
    padding: 10,
    paddingLeft: 15,
    fontWeight: 500,
    fontSize: 14,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 8,
    paddingLeft: 15,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default CableUtilizationView;
interface CableUtilzProps {
  allzone: ZoneList[];
  allstation: StationList[];
  cable_list: CableList[];
  allFMS: FMSList[];
  allfmslocation: FMSLocationList[];
  getZoneStations?: any;
  getStationLocation?: any;
  GetFMSLocationWiseAction?: any;
  showFmsWiseCableAction?: any;
  Filters?: any;
  clear?: any;
  utilizeCable: UtilizeCable[];
}
